import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";

const SelectInput = (props) => {
  const [ selectedOption, setSelectedOption ] = useState(null);

  useEffect(() => {
    if (props.value) {
      let option = props.options.find(option => option.value === props.value);

      if (option) {
        setSelectedOption(option);
      } else {
        setSelectedOption(null);
      }
    } else {
      setSelectedOption(null);
    }
  }, [props.value]);

  const handleOnChange = (option) => {
    if (option) {
      setSelectedOption(option.value);
      props.onChange(option.value);
    } else {
      setSelectedOption(null);
      props.onChange(null);
    }
  }

  return (
    <Dropdown>
      <Dropdown.Toggle className="application-filter">
        { selectedOption !== null ? selectedOption.label : props.label }
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          active={props.value === null}
          onClick={e => handleOnChange(null)}
        >
          { props.allLabel }
        </Dropdown.Item>

        { props.options.map(option => (
          <Dropdown.Item
            key={option.value}
            active={props.value === option.value}
            onClick={e => handleOnChange(option)}
          >
            { option.label }
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>  
  );
}

SelectInput.defaultProps = {
  value: null,
  options: [],
  onChange: () => {},
  label: null,
  allLabel: null,
}

export default SelectInput;
