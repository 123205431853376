import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { logout } from "store/actions/auth";
import { searchApplications } from "services";
import MenuLink from "./MenuLink";
import Notifications from "./Notifications";

import { ReactComponent as CustomersIcon } from "assets/images/menu/customers.svg";
import { ReactComponent as DashboardIcon } from "assets/images/menu/dashboard.svg";
import { ReactComponent as LendersIcon } from "assets/images/menu/lenders.svg";
import { ReactComponent as LogOutIcon } from "assets/images/menu/logout.svg";
import { ReactComponent as MenuIcon } from "assets/images/menu.svg";
import { ReactComponent as NotificationsIcon } from "assets/images/menu/notifications.svg";
import { ReactComponent as SearchIcon } from "assets/images/search.svg";
import { ReactComponent as QuoteIcon } from "assets/images/menu/quote.svg";
import logoIcon from "assets/images/logo.png";

const Header = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showNotificationsMenu, setShowNotificationsMenu] = useState(false);

  const isAdmin = useSelector((state) => state?.auth?.isAdmin);

  const handleLogout = (e) => {
    dispatch(logout());
  };

  const checkForEnter = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (searchTerm) {
      navigate(`/customers?search=${searchTerm}`);
    }
  };

  return (
    <>
      <header className="d-none d-lg-flex">
        {isAdmin === false ? (
          <div className="search-container">
            <input
              type="text"
              name="search"
              placeholder="Search customers"
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={(e) => checkForEnter(e)}
            />
            <SearchIcon onClick={handleSearch} />
          </div>
        ) : (
          <div />
        )}
        <div className="links-container">
          <ul>
            <li>
              <Link to="/help">Help</Link>
            </li>
            <li>
              <a href="/" onClick={handleLogout}>
                Log out
              </a>
            </li>
          </ul>
        </div>
      </header>
      <header className="mobile-header d-flex d-lg-none">
        <div className="header-logo">
          <img src={logoIcon} />
        </div>
        <div className="header-menu">
          <a onClick={(e) => setMenuOpen(!menuOpen)}>
            <MenuIcon />
          </a>
        </div>
      </header>
      <menu className={`mobile-menu d-lg-none ${menuOpen ? "open" : ""}`}>
        <li>
          <div className="search-container">
            <SearchIcon />
            <input type="text" name="search" placeholder="Search customers" />
          </div>
        </li>

        <MenuLink
          href="/"
          icon={<DashboardIcon className="stroke" />}
          text="Dashboard"
          closeMenu={() => setMenuOpen(false)}
        />

        <MenuLink
          href="/lenders"
          icon={<LendersIcon className="fill" />}
          text="Lenders"
          closeMenu={() => setMenuOpen(false)}
        />

        <MenuLink
          href="/customers"
          icon={<CustomersIcon className="stroke" />}
          text="Customers"
          closeMenu={() => setMenuOpen(false)}
        />

        {/*
        <MenuLink
          icon={<NotificationsIcon className="fill" />}
          text="Notifications"
          onClick={e => setShowNotificationsMenu(!showNotificationsMenu)}
          liClassName="position-relative"
        >
          { showNotificationsMenu && (
            <Notifications
              total={totalNotificationCount()}
              onHide={e => setShowNotificationsMenu(false)}
              { ...notificationsCounts }
            />
          )}
        </MenuLink>
        */}

        <MenuLink
          href="/quotes/new"
          icon={<QuoteIcon />}
          text="Add new quote"
          className="add-text"
          closeMenu={() => setMenuOpen(false)}
        />

        <MenuLink onClick={handleLogout} icon={<LogOutIcon />} text="Log out" />
      </menu>
    </>
  );
};

export default Header;
