import React, { useState } from "react";
import { Accordion, Modal, Spinner } from "react-bootstrap";

import FullQuoteStep from "./FullQuoteStep";
import LenderLogo from "components/LenderLogo";

const SendToCustomer = (props) => {
  const [customEmail, setCustomEmail] = useState("");
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [showError, setShowError] = useState(false);

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setSelectedEmail(e.target.value);
    } else {
      setSelectedEmail(null);
    }
  }

  const handleOnSuccess = () => {
    if (customEmail) {
      setShowError(false);
      props.onSuccess(customEmail);
    } else if (selectedEmail) {
      setShowError(false);
      props.onSuccess(selectedEmail);
    } else {
      setShowError(true);
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      centered
      className="blurred"
    >
      <Modal.Header closeButton>
        Full finance quote

        { props.lender && (
          <LenderLogo image={props.lender?.lenderLogo} />
        )}
      </Modal.Header>
      <Modal.Body className="send-to-customer-modal">
        <Accordion>
          { props.quotes.map(quote => (
            <Accordion.Item eventKey={quote.quoteGuid}>
              <Accordion.Header>Quote Details</Accordion.Header>
              <Accordion.Body>
                <FullQuoteStep
                  quote={quote}
                  showFooter={false}
                  showHeader={false}
                />
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        
        <div className="modal-options">
          <div>Choose customers email to send to:</div>
          <div className="modal-checkbox">
            <div>
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  value={props.application?.client?.clientEmail}
                  checked={selectedEmail === props.application?.client?.clientEmail}
                  onChange={handleCheckboxChange}
                />
                <span>
                  <span />
                </span>
              </label>
            </div>
            <div>
              { props.application?.client?.clientEmail}
            </div>
          </div>
        </div> 

        <div className="modal-options">
          <div>Enter new email to send quote to: </div>
          <div className="info">
            <input type="email" name="email" value={customEmail} onChange={e => setCustomEmail(e.target.value)} />
            (this email will be added to client record)
          </div>
        </div>

        { showError && (
          <div className="modal-options">
            <p className="error">Please select an email address or provide an alternative using the input above.</p>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        <button
          onClick={props.onHide}
          className="outline"
          disabled={props.loading}
        >
          Go back
        </button>
        <button
          onClick={handleOnSuccess}
          className="green"
          disabled={props.loading}
        >
          { props.loading ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <>Send to customer</>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default SendToCustomer;
