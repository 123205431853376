import React, { useEffect, useState } from "react";

import MenuLink from "./MenuLink";
import Notifications from "./Notifications";

import { ReactComponent as CustomersIcon } from "assets/images/menu/customers.svg";
import { ReactComponent as DashboardIcon } from "assets/images/menu/dashboard.svg";
import { ReactComponent as LendersIcon } from "assets/images/menu/lenders.svg";
import { ReactComponent as NotificationsIcon } from "assets/images/menu/notifications-icon.svg";
import { ReactComponent as QuoteIcon } from "assets/images/menu/quote.svg";

const MobileBottomMenu = (props) => {
  const [ showNotificationsMenu, setShowNotificationsMenu ] = useState(false);

  return (
    <div className="mobile-bottom-menu d-lg-none">
      <ul>
        <MenuLink
          href="/"
          icon={<DashboardIcon className="stroke" />}
        />

        <MenuLink
          href="/lenders"
          icon={<LendersIcon className="fill" />}
        />

        <MenuLink
          href="/customers"
          icon={<CustomersIcon className="stroke" />}
        />

        <MenuLink
          icon={<NotificationsIcon className="fill" />}
          onClick={e => setShowNotificationsMenu(!showNotificationsMenu)}
        >
         { showNotificationsMenu && (
            <Notifications
              onHide={e => setShowNotificationsMenu(false)}
              className="mobile-notifications-menu"
            />
          )}
        </MenuLink>

        <MenuLink
          href="/quotes/new"
          icon={<QuoteIcon />}
          className="add-text"
        />
      </ul>
    </div>
  )
}

export default MobileBottomMenu;
