import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

import { ReactComponent as ApplicationPendingIcon } from "assets/images/application-pending.svg";
import { getApplication } from "services";

const ApplicationPending = (props) => {
  const getDecision = () => {
    getApplication({ applicationGuid: props.application?.applicationGuid })
      .then(res => {
        if (res.quoteStatus !== "") {
          props.setStep(res?.quoteStatus.toLowerCase());
        }
      });
  }

  useEffect(() => {
    getDecision();

    let interval = setInterval(() => {
      getDecision();
    }, 30000);

    return () => {
      clearInterval(interval);
    }
  }, []);

  return (
    <>
      <Modal.Header closeButton>
        Lender decision
      </Modal.Header>
      <Modal.Body className="lender-decision-container">
        <div className="lender-decision">
          <ApplicationPendingIcon />

          <h3 className="section-title">The finance application has been submitted and we are awaiting a decision</h3>

          <p className="info">Sometimes lenders take longer than usual to process a decision. This could take up to 30 minutes.</p>
        </div>
      </Modal.Body>
    </>
  );
}

export default ApplicationPending;
