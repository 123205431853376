import React from "react";
import { useDispatch } from "react-redux";

import { setFrequency } from "store/actions/lenders"

const PageSwitcher = (props) => {
  const dispatch = useDispatch();

  const handleChange = (e, value) => {
    e.preventDefault();
    props.onChange(value);
    dispatch(setFrequency(value));
  }

  return (
    <div className="page-switcher">
      { props.options.map(option => (
        <a
          href="/"
          onClick={e => handleChange(e, option.value)}
          className={`${props.selected === option.value ? "selected" : ""}`}
          key={option.value}
        >
          { option.title }
        </a>
      ))}
    </div>
  );
}

PageSwitcher.defaultProps = {
  options: [],
  selected: null,
  onChange: () => {},
}

export default PageSwitcher;
