import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import * as yup from "yup";

import { getErrorLabels } from "helpers/errors";
import { getBlankApplication, getMonthOptions, getYearOptions } from "helpers/application"
import Error from "components/Error";

const PersonalDetails = (props) => {

  const metaData = useSelector(state => state?.lender.metaData);

  const [ clientDetails, setClientDetails ] = useState({
    title: "",
    maritalStatus: "",
    typeOfResidence: "",
    years: "",
    months: "0",
    dateOfBirth: moment().format("yyyy-MM-DD"),
  });

  const [ previousAddress, setPreviousAddress ] = useState({
    streetNumber: "",
    streetName: "",
    postcode: "",
    months: "0",
    years: "",
  });

  const [ previousAddressValidationSchema, setPreviousAddressValidationSchema ] = useState({});
  const [ validationSchema, setValidationSchema ] = useState(null);

  const [ errors, setErrors ] = useState({});

  useEffect(() => {
    if (Object.keys(props.schema).length > 0) {
      setValidationSchema(yup.object({
        title: yup.mixed().oneOf(props?.schema?.properties?.client?.properties?.title?.enum ?? []),
        firstName: yup.string().matches(props?.schema?.properties?.client?.properties?.firstName?.pattern).required(),
        lastName: yup.string().required(),
        dateOfBirth: yup.date().required(),
        maritalStatus: yup.mixed().oneOf(props?.schema?.properties?.client?.properties?.maritalStatus?.enum ?? []),
        cellNumber: yup.string().length(11).required(),
        clientEmail: yup.string().email().required(),
        streetNumber: yup.string().matches(props?.schema?.properties?.client?.properties?.currentAddress?.properties?.streetNumber?.pattern).required(),
        streetName: yup.string().matches(props?.schema?.properties?.client?.properties?.currentAddress?.properties?.streetName?.pattern).required(),
        postcode: yup.string().matches(props?.schema?.properties?.client?.properties?.currentAddress?.properties?.postcode?.pattern).required(),
        months: yup.number().min(0).max(11).required(),
        years: yup.number().min(0).max(40).required(),
        typeOfResidence: yup.mixed().oneOf(props?.schema?.properties?.client?.properties?.typeOfResidence?.enum ?? []),
      }));

      setPreviousAddressValidationSchema({
        streetNumber: yup.string().matches(props?.schema?.properties?.client?.properties?.currentAddress?.properties?.streetNumber?.pattern).required(),
        streetName: yup.string().matches(props?.schema?.properties?.client?.properties?.currentAddress?.properties?.streetName?.pattern).required(),
        postcode: yup.string().matches(props?.schema?.properties?.client?.properties?.currentAddress?.properties?.postcode?.pattern).required(),
        months: yup.number().min(0).max(11).required(),
        years: yup.number().min(0).max(40).required(),
      });
    }
  }, [ props.schema ]);

  const getAddress = (field = "address", current = true) => {
    let addressFields = [];

    let address;
    if (current) {
      address = props.application?.client?.addressList[0];
    } else {
      address = props?.application?.client?.addressList[1];
    }

    if (address) {
      if (field === "address") {
        ["addressLine1", "addressLine2", "addressLine3", "addressLine4", "addressLine5"].map(field => {
          if (address[field]) {
            addressFields.push(address[field]);
          }
        });
      } else if (field === "postcode") {
        addressFields.push(address.postCode);
      } else if (field === "time") {
        let totalMonths = address.monthsAtAddress;

        let years = Math.floor(totalMonths / 12);
        let months = (totalMonths % 12) * 12;

        if (years > 0) {
          addressFields.push(`${years} years`);
        }

        if (months > 0) {
          addressFields.push(`${months} months`);
        }
      }

      return addressFields.join(", ");
    }
    return "";
  };

  const getCurrentMonthsAtAddress = () => {
    if (props.application?.client) {
      return props?.application?.client?.addressList[0]?.monthsAtAddress;
    } else {
      return parseInt(clientDetails.months) + (parseInt(clientDetails.years ?? 0) * 12);
    }
  }

  const handleInputChange = (e) => {
    setClientDetails({
      ...clientDetails,
      [e.target.name]: e.target.value,
    });
  }

  const handlePreviousAddressChange = (e) => {
    setPreviousAddress({
      ...previousAddress,
      [e.target.name]: e.target.value,
    });
  }

  const handleSaveSection = () => {
    let prevAddrSchema = yup.object();
    let prevAddr = {};
    let prevAddrObj = [];

    if (getCurrentMonthsAtAddress() < 36) {
      prevAddrSchema = yup.object({
        previous: yup.object(previousAddressValidationSchema),
      });

      prevAddr = {
        streetNumber: previousAddress?.streetNumber,
        streetName: previousAddress?.streetName,
        postcode: previousAddress?.postcode,
        months: previousAddress?.months,
        years: previousAddress?.years,
      }

      prevAddrObj = [{
        addressLine1: previousAddress?.streetNumber,
        addressLine2: previousAddress?.streetName,
        postCode: previousAddress?.postcode,
        monthsAtAddress: (parseInt(previousAddress?.years) * 12) + parseInt(previousAddress?.months),
      }]
    }

    validationSchema
      .concat(prevAddrSchema)
      .validate({ ...clientDetails, previous: prevAddr }, { abortEarly: false })
      .then(() => {
        const createApplication = getBlankApplication(props.application);

        props.onUpdate({
          client: {
            ...createApplication?.client,
            title: clientDetails?.title,
            firstName: clientDetails?.firstName,
            lastName: clientDetails?.lastName,
            dateOfBirth: clientDetails?.dateOfBirth,
            clientCellNumber: clientDetails?.cellNumber,
            clientEmail: clientDetails?.clientEmail,
            maritalStatus: clientDetails?.maritalStatus,
            typeOfResidence: clientDetails?.typeOfResidence,
            addressList: [
              {
                addressLine1: clientDetails?.streetNumber,
                addressLine2: clientDetails?.streetName,
                postCode: clientDetails?.postcode,
                monthsAtAddress: (parseInt(clientDetails?.years) * 12) + parseInt(clientDetails?.months),
              },
              ...prevAddrObj,
            ],
          }
        });

        props.changeTab("occupation");
      }).catch(err => {
        setErrors(getErrorLabels(err));
      });
  }

  return (
    <div className="customer-details">
      <Row>
        <Col xs={12} md={3}>
          <label>Title</label>
          <div>
            { props.application ? (
              <>{ props.application?.client?.title }</>
            ) : (
              <>
                <Form.Select name="title" value={clientDetails?.title} onChange={handleInputChange}>
                  <option value=""></option>
                  { metaData?.titleList?.map(option => (
                    <option value={option} key={option}>{ option }</option>
                  ))}
                </Form.Select>
                <Error name="title" errors={errors} />
              </>
            )}
          </div>
        </Col>
        <Col xs={6} md={3}>
          <label>First name</label>
          <div>
            { props.application ? (
              <>{ props.application?.client?.firstName }</>
            ) : (
              <>
                <input name="firstName" onChange={handleInputChange} />
                <Error name="firstName" errors={errors} />
              </>
            )}
          </div>
        </Col>
        <Col xs={6} md={3}>
          <label>Last name</label>
          <div>
            { props.application ? (
              <>{ props.application?.client?.lastName }</>
            ) : (
              <>
                <input name="lastName" onChange={handleInputChange} />
                <Error name="lastName" errors={errors} />
              </>
            )}
          </div>
        </Col>
        <Col xs={6} md={3}>
          <label>D/O/B</label>
          <div>
            { props.application ? (
              <>{ moment(props.application?.client?.dateOfBirth).format("DD-MMM-YY") }</>
            ) : (
              <>
                <input name="dateOfBirth" type="date" onChange={handleInputChange} value={clientDetails.dateOfBirth ?? ""} />
                <Error name="dateOfBirth" errors={errors} />
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={6} md={3}>
          <label>Marital Status</label>
          <div>
            { props.application ? (
              <>{ props.application?.client?.maritalStatus }</>
            ) : (
              <>
                <Form.Select name="maritalStatus" value={clientDetails?.maritalStatus} onChange={handleInputChange}>
                  <option value=""></option>
                  { metaData?.maritalStatusList?.map(option => (
                    <option value={option} key={option}>{ option }</option>
                  ))}
                </Form.Select>
                <Error name="maritalStatus" errors={errors} />
              </>
            )}
          </div>
        </Col>
        <Col xs={6} md={3}>
          <label>Telephone number</label>
          <div>
            { props.application ? (
              <>{ props.application?.client?.clientCellNumber }</>
            ) : (
              <>
                <input name="cellNumber" onChange={handleInputChange} />
                <Error name="cellNumber" errors={errors} />
              </>
            )}
          </div>
        </Col>
        <Col xs={6} md={3}>
          <label>Email address</label>
          <div>
            { props.application ? (
              <>{ props.application?.client?.clientEmail }</>
            ) : (
              <>
                <input name="clientEmail" type="email" onChange={handleInputChange} />
                <Error name="clientEmail" errors={errors} />
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <label>Address</label>
          <div>
            { props.application ? (
              <>{ getAddress() }</>
            ) : (
              <>
                <input
                  name="streetNumber"
                  placeholder="Property Name/Number"
                  onChange={handleInputChange}
                  className="d-block w-100 mb-2"
                />
                <Error name="streetNumber" errors={errors} />

                <input
                  name="streetName"
                  placeholder="Street Name"
                  onChange={handleInputChange}
                  className="d-block w-100"
                />
                <Error name="streetName" errors={errors} />
              </>
            )}
          </div>
        </Col>
        <Col xs={12} md={2}>
          <label>Postcode</label>
          <div>
            { props.application ? (
              <>{ getAddress("postcode") }</>
            ) : (
              <>
                <input name="postcode" onChange={handleInputChange} />
                <Error name="postcode" errors={errors} />
              </>
            )}
          </div>
        </Col>
        <Col xs={12} md={4}>
          <label>Time at current address</label>
          <div>
            { props.application ? (
              <>{ getAddress("time") }</>
            ) : (
              <Row className="mb-0">
                <Col xs={6}>
                  <Form.Select name="years" value={clientDetails?.years} onChange={handleInputChange}>
                    <option value=""></option>
                    { getYearOptions().map(option => (
                      <option value={option.value} key={option.value}>{ option.label }</option>
                    ))}
                  </Form.Select>
                  <Error name="years" errors={errors} />
                </Col>
                <Col xs={6}>
                  <Form.Select name="months" value={clientDetails?.months} onChange={handleInputChange}>
                    <option value=""></option>
                    { getMonthOptions().map(option => (
                      <option value={option.value} key={option.value}>{ option.label }</option>
                    ))}
                  </Form.Select>
                  <Error name="months" errors={errors} />
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={6} md={3}>
          <label>Type of Residence</label>
          <div>
            { props.application ? (
              <>{ props.application?.client?.typeOfResidence }</>
            ) : (
              <>
                <Form.Select name="typeOfResidence" value={clientDetails?.typeOfResidence} onChange={handleInputChange}>
                  <option value=""></option>
                  { metaData?.residenceTypeList?.map(option => (
                    <option value={option} key={option}>{ option }</option>
                  ))}
                </Form.Select>
                <Error name="typeOfResidence" errors={errors} />
              </>
            )}
          </div>
        </Col>
      </Row>
      { getCurrentMonthsAtAddress() < 36 && (
        <>
          <p className="additional-text">As the customer has been with their current address for less than 3 years, we need details of past address.</p>

          <Row>         
            <Col xs={12} md={6}>
              <label>Previous Address</label>
              <div> 
                { props.application ? (
                  <>{ getAddress("address", false) }</>
                ) : (
                  <>
                    <input
                      name="streetNumber" 
                      placeholder="Property Name/Number"
                      onChange={handlePreviousAddressChange}
                      className="d-block w-100 mb-2"
                    />
                    <Error name="previous.streetNumber" errors={errors} />

                    <input
                      name="streetName"
                      placeholder="Street Name"
                      onChange={handlePreviousAddressChange}
                      className="d-block w-100"
                    />
                    <Error name="previous.streetName" errors={errors} /> 
                  </>   
                )}    
              </div>
            </Col>  
            <Col xs={12} md={2}>
              <label>Previous Postcode</label>
              <div>
                { props.application ? (
                  <>{ getAddress("postcode", false) }</>
                ) : (
                  <>
                    <input name="postcode" onChange={handlePreviousAddressChange} />
                    <Error name="previous.postcode" errors={errors} />
                  </>
                )}
              </div>
            </Col>
            <Col xs={12} md={4}>
              <label>Time at current address</label>
              <div> 
                { props.application ? (
                  <>{ getAddress("time", false) }</>
                ) : (
                  <Row className="mb-0">
                    <Col xs={6}>
                      <Form.Select name="years" value={previousAddress?.years} onChange={handlePreviousAddressChange}>
                        <option value=""></option>
                        { getYearOptions().map(option => (
                          <option value={option.value} key={option.value}>{ option.label }</option>
                        ))}
                      </Form.Select>
                      <Error name="previous.years" errors={errors} />
                    </Col>
                    <Col xs={6}>
                      <Form.Select name="months" value={previousAddress?.months} onChange={handlePreviousAddressChange}>
                        <option value=""></option>
                        { getMonthOptions().map(option => (
                          <option value={option.value} key={option.value}>{ option.label }</option>
                        ))}
                      </Form.Select>
                      <Error name="previous.months" errors={errors} />
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </>
      )}
      { !props.application && (
        <Row className="mb-0">
          <Col xs={12} className="d-flex justify-content-end">
            <button
              className="dark"
              onClick={handleSaveSection}
            >
              Save customer & continue
            </button>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default PersonalDetails;
