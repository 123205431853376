import React from "react";

const CustomerStatus = (props) => {
  return (
    <div className={`customer-status ${props.status.toLowerCase().replace(/[^a-z0-9]/, '_')}`}>
      { props.status }
    </div>
  )
}

export default CustomerStatus;
