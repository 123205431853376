import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { getDashboardData, getMetaData } from "services";
import LenderLogo from "components/LenderLogo";
import PageSwitcher from "components/PageSwitcher";
import ResponsiveTable from "components/table/ResponsiveTable";
import StatsCard from "components/StatsCard";

const DashboardPage = (props) => {
  const [ dashboardData, setDashboardData ] = useState({});

  const frequency = useSelector(state => state?.lender?.frequency);

  const navigate = useNavigate();

  useEffect(() => {
    getDashboardData({ frequency })
      .then(res => {
        setDashboardData(res);
      });
  }, [frequency]);

  return (
    <div>
      <div className="page-top">
        <h3 className="page-title">Dashboard</h3>

        <PageSwitcher
          options={[
            {
              title: "Today",
              value: "Day",
            },
            { 
              title: "Week",
              value: "Week",
            },
            { 
              title: "Month",
              value: "Month",
            },
            { 
              title: "Year",
              value: "Year",
            },
          ]}
          selected={frequency}
        />
      </div>

      <div className="stats-card-container">
        <StatsCard
          title="Total pre-approved customers"
          figure={dashboardData?.totalPrevalidated}
          percent={dashboardData?.totalPevalidatedPercentage}
          onClick={e => navigate('/customers?status=Prevalidated')}
        />

        <StatsCard
          title="Applications not submitted to lender"
          figure={dashboardData?.totalNotSubmitted}
          percent={dashboardData?.totalNotSubmittedPercentage}
          background="blue"
          onClick={e => navigate('/customers?status=NotSubmitted')}
        />

        <StatsCard
          title="Approved by lender"
          figure={dashboardData?.totalApproved}
          percent={dashboardData?.totalApprovedPercentage}
          background="green"
          onClick={e => navigate('/customers?status=Approved')}
        />

        <StatsCard
          title="Declined by lender"
          figure={dashboardData?.totalDeclined}
          percent={dashboardData?.totalDeclinedPercentage}
          background="light"
          onClick={e => navigate('/customers?status=Declined')}
        />
      </div>

      <div className="content-card">
        <div className="content-top">
          <div className="content-title">Overall performance <span>({ frequency })</span></div>

          <PageSwitcher
            options={[
              {
                title: "Today",
                value: "Day",
              },
              { 
                title: "Week",
                value: "Week",
              },
              { 
                title: "Month",
                value: "Month",
              },
              {
                title: "Year",
                value: "Year",
              },
            ]}
            selected={frequency}
          />
        </div>

        <div className="content-card-content">
          { dashboardData?.lenderList && (
            <ResponsiveTable
              columns={[
                {
                  title: "Lenders",
                  render: (row) => (
                    <LenderLogo image={row.lenderLogo} />
                  ),
                  showMobileHeader: false,
                },
                {
                  title: "Total submitted",
                  index: "totalSubmitted",
                },
                {
                  title: "Approved",
                  index: "totalApproved",
                },
                {
                  title: "Approved percentage",
                  render: (row) => (
                    <span className="percentage-cell">
                      { row.totalApprovedPercentage }
                      <span>%</span>
                    </span>
                  ),
                },
                {
                  title: "Declined",
                  index: "totalDeclined",
                },
                {
                  title: "Declined percentage",
                  render: (row) => (
                    <span className="percentage-cell">
                      { row.totalDeclinedPercentage }
                      <span>%</span>
                    </span>
                  ),
                },
              ]}
              data={dashboardData.lenderList}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
