import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { Modal, Spinner } from "react-bootstrap";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { getErrorLabels } from "helpers/errors";
import Error from "components/Error";

const DealerModal = (props) => {
  const [ errors, setErrors ] = useState({});
  const [ formData, setFormData ] = useState({});
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    if (props.dealer) {
      let editDealer = { ...props.dealer };

      if (editDealer?.idd_text) {
        let termsContent = ContentState.createFromBlockArray(htmlToDraft(props?.dealer.idd_text));

        editDealer.idd_text = EditorState.createWithContent(termsContent);
      } else {
        editDealer.idd_text = EditorState.createEmpty();
      }

      setFormData(editDealer);
    } else {
      setFormData({});
    }
  }, [props.dealer]);

  const handleConfirm = () => {
    setLoading(true);
    let localFormData = { ...formData };
    localFormData.idd_text = draftToHtml(convertToRaw(formData.idd_text.getCurrentContent()));
    props.onConfirm(localFormData);
    setLoading(false);
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  const handleTextChange = (editorState) => {
    setFormData({
      ...formData,
      idd_text: editorState,
    });
  }

  const handleFileUpload = (e) => {
    if (e.target.files) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
      });
    }
  }

  return (
    <Modal show={props.show} onHide={props.onCancel} size="lg" className="confirm-modal dealer-form-modal">
      <Modal.Header closeButton>
        <Modal.Title>Manage Dealer</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dealer-form form-container">
        <Row>
          <Col xs={12} className="form-item">
            <label>Dealer name</label>
            <div>
              <input
                type="text"
                name="name"
                value={formData?.name ?? undefined}
                onChange={handleChange}
              />
              <Error name="name" errors={errors} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={5} className="form-item">
            <label>Dealer API key</label>
            <div>
              <input
                type="text"
                name="api_key"
                value={formData?.api_key ?? undefined}
                onChange={handleChange}
              />
              <Error name="api_key" errors={errors} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="form-item">
            <label>Codeweaver ID</label>
            <div>
              <input
                type="text"
                name="codeweaver_id"
                value={formData?.codeweaver_id ?? undefined}
                onChange={handleChange}
              />
              <Error name="codeweaver_id" errors={errors} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="form-item">
            <label>Dealer logo</label>
            <div>
              <input
                type="file"
                accept="image/*"
                name="logo"
                onChange={handleFileUpload}
              />
              <Error name="logo" errors={errors} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="form-item">
            <label>Initial Disclosure Document (IDD) PDF</label>
            <div>
              <input
                type="file"
                accept="application/pdf"
                name="idd"
                onChange={handleFileUpload}
              />
              <Error name="idd" errors={errors} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="form-item">
            <label>Initial Disclosure Document (IDD) Text</label>
            <div>
              <Editor
                editorState={formData.idd_text}
                onEditorStateChange={handleTextChange}
              />
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="grey"
          onClick={props.onCancel}
        >
          Cancel
        </button>
        <button
          className="dark"
          onClick={handleConfirm}
        >
          { loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>Confirm</>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default DealerModal;
