import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

const ApplicationFilter = (props) => {
  const handleChange = (val) => {
    props.onChange(val);
  }

  const options = [
    {
      value: "Prevalidated",
      label: "Pre-Validated",
    },
    {
      value: "NotSubmitted",
      label: "Not Submitted",
    },
    {
      value: "Approved",
      label: "Approved",
    },
    {
      value: "Declined",
      label: "Declined",
    },
  ];

  return (
    <Dropdown>
      <Dropdown.Toggle className="application-filter">
        Filter by
      </Dropdown.Toggle>

      <Dropdown.Menu>
        { options.map(option => (
          <Dropdown.Item
            key={option.value}
            active={props?.value === option.value}
            onClick={e => handleChange(option.value)}
          >
            { option.label }
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ApplicationFilter;
