import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as yup from "yup";

import {
  getApplicationUpdateObject,
  getBlankApplication,
  getMonthOptions,
  getYearOptions
} from "helpers/application"
import { getErrorLabels } from "helpers/errors";
import Error from "components/Error";

const OccupationDetails = (props) => {
  const [ employment, setEmployment ] = useState({
    years: "",
    months: "0",
  });
  const [ employmentStatus, setEmploymentStatus ] = useState("");
  const [ errors, setErrors ] = useState({});
  const [ income, setIncome ] = useState(0);
  const [ occupationStatus, setOccupationStatus ] = useState("");

  const metaData = useSelector(state => state?.lender?.metaData);

  const validationSchema = yup.object({
    occupationStatus: yup.mixed().oneOf(props.schema?.properties?.client?.properties?.occupationStatus?.enum ?? []).required(),
    employmentStatus: yup.mixed().oneOf(props.schema?.properties?.client?.properties?.employmentStatus?.enum ?? []).required(),
    monthsAtJob: yup.number().min(0).max(11).required(),
    yearsAtJob: yup.number().max(40).required(),
    income: yup.number().min(5000).required(),
  });

  useEffect(() => {
    if (props.application?.client?.monthsAtJob) {
      let years = Math.floor(props.application?.client?.monthsAtJob / 12);
      let months = props.application?.client?.monthsAtJob % 12;

      setEmployment({
        years,
        months,
      });
    }

    if (props.application?.client?.occupationStatus) {
      setOccupationStatus(props.application?.client?.occupationStatus);
    }

    if (props.application?.client?.employmentStatus) {
      setEmploymentStatus(props.application?.client?.employmentStatus);
    }

    if (props.application?.client?.income) {
      setIncome(props.application?.client?.income);
    }
  }, [props.application]);

  const handleTimeChange = (e) => {
    setEmployment({
      ...employment,
      [e.target.name]: e.target.value,
    });
  }

  const updateApplication = () => {
    validationSchema.validate({
      occupationStatus,
      employmentStatus,
      income,
      monthsAtJob: parseInt(employment.months),
      yearsAtJob: parseInt(employment.years),
    },
    {
      abortEarly: false
    }).then(() => {
      if (props?.application?.applicationGuid) {
        let updateObj = getApplicationUpdateObject(props.application);

        props.onUpdate({
          client: {
            ...updateObj.client,
            monthsAtJob: parseInt(employment.months),
            yearsAtJob: parseInt(employment.years),
            occupationStatus,
            employmentStatus,
            income,
          },
        });
      } else {
        let applicationObj = getBlankApplication(props.application);

        props.onUpdate({
          client: {
            ...applicationObj.client,
            monthsAtJob: (parseInt(employment.years) * 12) + parseInt(employment.months),
            occupationStatus,
            employmentStatus,
            income,
          }
        });
      }

      props.changeTab("driving");
    }).catch(err => {
      console.log('err', err);
      setErrors(getErrorLabels(err));
    });
  }

  return (
    <div className="customer-details">
      <Row>
        <Col xs={12}>
          <label>What's the customer's occupation</label>
          <div>
            <Form.Select value={occupationStatus} onChange={e => setOccupationStatus(e.target.value)}>
              <option value=""></option>
              { metaData?.occupationStatusList?.map(option => (
                <option value={option} key={option}>{ option }</option>
              ))}
            </Form.Select>
            <Error name="occupationStatus" errors={errors} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <label>What's the customer's employment status?</label>
          <div>
            <Form.Select value={employmentStatus} onChange={e => setEmploymentStatus(e.target.value)}>
              <option value=""></option>
              { metaData?.employmentStatusList?.map(option => (
                <option value={option} key={option}>{ option }</option>
              ))}
            </Form.Select>
            <Error name="employmentStatus" errors={errors} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <label>How long has the customer been with their current employer?</label>
          <Row className="mb-0">
            <Col xs={6} md={2}>
              <Form.Select name="years" value={employment.years} onChange={handleTimeChange}>
                <option value=""></option>
                { getYearOptions().map(option => (
                  <option value={option.value} key={option.value}>{ option.label }</option>
                ))}
              </Form.Select>
              <Error name="yearsAtJob" errors={errors} />
            </Col>
            <Col xs={6} md={2}>
              <Form.Select name="months" value={employment.months} onChange={handleTimeChange}>
                <option value=""></option>
                { getMonthOptions().map(option => (
                  <option value={option.value} key={option.value}>{ option.label }</option>
                ))}
              </Form.Select>
              <Error name="monthsAtJob" errors={errors} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <label>What's the customer's annual income?</label>
          <div>
            <input
              type="text"
              name="income"
              value={`£${parseInt(income ?? 0).toLocaleString()}`}
              onChange={e => setIncome(parseInt(e.target.value.replace(',', '').replace('£', '') || 0))}
            />
            <Error name="income" errors={errors} />
          </div>
        </Col>
      </Row>
      <Row className="align-items-end mb-0">
        <Col xs={12} className="d-flex">
          <button
            onClick={updateApplication}
            className="dark ms-auto"
          >
            Save customer & continue
          </button>
        </Col>
      </Row>
    </div>
  );
}

export default OccupationDetails;
