import React from "react";

import { ReactComponent as CardBackgroundIcon } from "assets/images/card-background.svg";

const StatsCard = (props) => {
  const handleOnClick = () => {
    props.onClick();
  }

  return (
    <div className={`stats-card ${props.background}`} onClick={handleOnClick}>
      <div className="stats-card-title">{ props.title }</div>

      <div className="stats-card-figure">{ props.figure }</div>

      <div className="stats-card-percent">{ props.percent }%</div>

      <CardBackgroundIcon />
    </div>
  )
}

StatsCard.defaultProps = {
  background: "white",
  onClick: () => {},
}

export default StatsCard;
