import React, { useEffect, useState } from "react";
import { FormCheck, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { getMetaData, getNotificationsCount } from "services";
import { setMetaData } from "store/actions/lenders";
import { setNotifications } from "store/actions/auth";
import Footer from "./navigation/Footer";
import Header from "./navigation/Header";
import Loader from "components/Loader";
import MobileBottomMenu from "./navigation/MobileBottomMenu";
import Sidebar from "./navigation/Sidebar";

const UserLayout = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const isAdmin = useSelector((state) => state?.auth?.isAdmin);
  const isLoading = useSelector((state) => state?.auth?.loading);
  const loadingMsg = useSelector((state) => state?.auth?.loadingMsg);
  const retailer = useSelector(state => state?.auth?.dealerGuid);

  const dispatch = useDispatch();

  const getNotifications = () => {
    if (!isAdmin) {
      getNotificationsCount({ retailer }).then((res) => {
        dispatch(setNotifications(res));
      });
    }
  };

  useEffect(() => {
    getMetaData().then((res) => {
      let metaData = { ...res };

      Object.keys(metaData).map((key) => {
        metaData[key].sort();
      });
      dispatch(setMetaData(metaData));
    });

    getNotifications();

    let interval = setInterval(() => {
      getNotifications();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleSidebarToggle = (e) => {
    e.preventDefault();
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div
      className={`user-layout ${
        sidebarOpen ? "sidebar-open" : "sidebar-closed"
      }`}
    >
      {isLoading && (
        <div className="page-overlay">
          <div className="loading-animation">
            <Loader />

            {loadingMsg && <h3 className="loading-message">{loadingMsg}</h3>}
          </div>
        </div>
      )}

      <Sidebar toggle={handleSidebarToggle} sidebarOpen={sidebarOpen} />

      <div className="content-container">
        <div className="content">
          <Header />

          <div className="content-inner">{props.children}</div>

          <MobileBottomMenu />

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default UserLayout;
