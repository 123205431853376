import * as yup from "yup";

export const getErrorLabels = (err) => {
  let valErrors = {};

  err.inner.map(error => {
    if (["required", "nullable", "optionality", "typeError"].includes(error.type)) {
      valErrors[error.path] = "This field is required";
    } else if (error.type === "length") {
      valErrors[error.path] = `Field must ${error.params.length} characters`;
    } else if (error.type === "oneOf") {
      valErrors[error.path] = "Please select an option";
    } else if (error.type === "min" && error.path === "income") {
      valErrors[error.path] = "This looks low. Please enter your annual income before tax";
    }
  });     

  return valErrors;                  
}

export const generateValidationSchema = (schema) => {
  let validationSchema = {};
  Object.keys(schema.properties).map(key => {
    let field = schema.properties[key];

    if (field.type === "object") {
      let subValidation = generateValidationSchema(field);

      if (schema.required && schema.required.includes(key)) {
        subValidation = subValidation.required();
      } else {
        subValidation = subValidation.default(undefined);
      }

      validationSchema = { ...validationSchema, [key]: subValidation }; 
    } else {
      let rule;
      if (field.enum) {
        rule = yup.mixed().oneOf(field.enum);
      } else if (field.type === "string") {
        rule = yup.string();
      } else if (field.type === "integer" || field.type === "number") {
        rule = yup.number();
      } else if (field.type === "boolean") {
        rule = yup.boolean();
      }

      if (field.pattern && field.type === "string") {
        rule = rule.matches(field.pattern);
      }

      if (field.minimum) {
        rule = rule.min(field.minimum);
      }

      if (field.maximum) {
        rule = rule.max(field.maximum);
      }

      if (schema.required && schema.required.includes(key)) {
        rule = rule.required();
      }

      if (rule) {
        validationSchema[key] = rule;
      }
    }
  });

  return yup.object(validationSchema);
}
