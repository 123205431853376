import React from "react";
import { Modal } from "react-bootstrap";

import { ReactComponent as ApplicationSuccessIcon } from "assets/images/application-success.svg";

const ApplicationSuccess = (props) => {
  return (
    <>
      <Modal.Header closeButton>
        Lender decision
      </Modal.Header>
      <Modal.Body className="lender-decision-container">
        <div className="lender-decision">
          <ApplicationSuccessIcon />

          <h3 className="section-title">Congratulations, the finance has been approved.</h3>

          <p className="info">The lender will now send the relevant documents and I.D.verification requirements to the customer.</p>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-start">
        <button
          className="outline"
          onClick={props.onBack}
        >
          Close
        </button>
      </Modal.Footer>
    </>
  );
}

export default ApplicationSuccess;
