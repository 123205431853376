import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";

import { formatCurrency } from "helpers/currency";
import { sendApplications } from "services";
import HPModal from "components/modals/HPModal";
import LenderLogo from "components/LenderLogo";
import PCPModal from "components/modals/PCPModal";
import SendToCustomer from "components/modals/quote/SendToCustomer";
import SubmitToLenderModal from "components/modals/SubmitToLenderModal";

import { ReactComponent as InfoIcon } from "assets/images/info.svg";

const PreQualifiedQuotes = (props) => {

  const [ sendLoading, setSendLoading ] = useState(false);
  const [ selectedQuotes, setSelectedQuotes ] = useState([]);
  const [ showSubmitToLenderModal, setShowSubmitToLenderModal ] = useState(false);
  const [ showHPModal, setShowHPModal ] = useState(false);
  const [ showLender, setShowLender ] = useState(null);
  const [ showPCPModal, setShowPCPModal ] = useState(false);
  const [ showQuote, setShowQuote ] = useState(null);
  const [ showSendToCustomerModal, setShowSendToCustomerModal ] = useState(false);

  const toggleCheckbox = (q, lender) => {
    let localQuotes = [ ...selectedQuotes ];
    if (localQuotes.map(quote => quote.quoteGuid).includes(q.quoteGuid)) {
      localQuotes = localQuotes.filter(currentQuote => q.quoteGuid !== currentQuote.quoteGuid);
    } else {
      q.lender = {
        lenderName: lender.lenderName,
        lenderLogo: lender.lenderLogo,
      };
      localQuotes.push(q);
    }

    setSelectedQuotes(localQuotes);
  }

  const onProductClick = (q) => {
    if (q.productType === "HP") {
      setShowHPModal(true);
    } else if (q.productType === "pcp") {
      setShowPCPModal(true);
    }
  }

  const onViewQuote = (quote = null, lender = null) => {
    setShowSubmitToLenderModal(quote !== null);
    setShowQuote(quote);
    setShowLender(lender);
  }

  const canShowSubmitToLender = (quote) => {
    if (props.application?.quoteStatus === "Approved") {
      return false;
    }

    if (quote.status === "Declined") {
      return false;
    }

    return true;
  }

  const handleShowSendModal = () => {
    setShowSendToCustomerModal(true);
  }

  const handleSendQuotes = (email) => {
console.log('selectedQuotes', selectedQuotes);
    setSendLoading(true);
    sendApplications({
      quotes: selectedQuotes,
      application: props.application?.applicationGuid,
      email: email,
    }).then(res => {
      setShowSendToCustomerModal(false);
    }).finally(() => {
      setSendLoading(false);
    });
  }

  return (
    <div>
      { props?.quotes?.lenderList?.map(lender => (
        <React.Fragment key={lender.lenderName}>
          { lender.quoteList.map(quote => (
            <Row className="quotes-table" key={quote.quoteGuid}>
              <Col xs={2} md="auto" className="align-content-center align-items-start align-items-md-center">
                <label className="custom-checkbox">
                  <input type="checkbox" onChange={e => toggleCheckbox(quote, lender)} checked={selectedQuotes.map(quote => quote.quoteGuid).includes(quote.quoteGuid)} />
                  <span>
                    <span />
                  </span>
                </label>
              </Col>
              <Col xs={8} md="auto" className="xs-order-1">
                <LenderLogo image={lender.lenderLogo} />
              </Col>
              <Col xs={6} md="auto" className="xs-order-2">
                <label>
                  Finance Type <InfoIcon width={13} height={13} onClick={e => onProductClick(quote)} className="info-tooltip" />
                </label>
                { quote.productTypeDesc }
              </Col>
              <Col xs={6} md="auto" className="xs-order-3">
                <label>
                  APR
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          This APR represents the interest rate applied to your finance agreement
                        </Tooltip>
                      }
                    >
                      <InfoIcon width={13} height={13} className="info-tooltip" />
                    </OverlayTrigger>
                </label>
                { quote.apr }%
              </Col>
              <Col xs={6} md="auto" className="xs-order-6">
                <label>Final payment</label>
                £{ formatCurrency(quote.residualValue) }
              </Col>
              <Col xs={6} md="auto" className="xs-order-7">
                <label>Total interest cost</label>
                £{ formatCurrency(quote.totalInterest) }
              </Col>
              <Col xs={6} md="auto" className="xs-order-5">
                <label>Total Payable</label>
                £{ formatCurrency(quote.totalPayable) }
              </Col>
              <Col xs={6} md="auto" className="xs-order-4">
                <label>Monthly amount</label>
                £{ formatCurrency(quote.payments) }
              </Col>
              <Col xs={12} md={2} className="quotes-table-buttons xs-order-8">
                { props.application?.applicationStatus !== "ProposalSubmitted" ? (
                  <>
                    <button
                      onClick={e => onViewQuote(quote, lender)}
                      className="primary small"
                      disabled={props.quoteChanged}
                    >
                      View full quote
                    </button>
                    { canShowSubmitToLender(quote) && (
                      <button
                        onClick={e => onViewQuote(quote, lender)}
                        className="green small"
                        disabled={props.quoteChanged}
                      >
                        Submit to lender
                      </button>
                    )}
                  </>
                ) : quote?.status === "Submitted To Lender" && (
                  <button
                    onClick={e => onViewQuote(quote, lender)}
                    className="green small"
                  >
                    View Decision
                  </button>
                )}
              </Col>
            </Row>
          ))}
        </React.Fragment>
      ))}

      <Row>
        <Col xs={12}>
          <div className="d-flex justify-content-end align-items-center" style={{ gap: 20 }}>
            <div className="send-quote-message">Please select which quote(s) above to send to customer</div>
            <button
              onClick={handleShowSendModal}
              disabled={selectedQuotes.length === 0}
              className="green"
            >
              Send to customer
            </button>
          </div>
        </Col>
      </Row>

      <HPModal show={showHPModal} onHide={e => setShowHPModal(false)} />
      <PCPModal show={showPCPModal} onHide={e => setShowPCPModal(false)} />

      <SubmitToLenderModal
        show={showSubmitToLenderModal}
        quote={showQuote}
        allQuotes={props?.quotes ?? []}
        lender={showLender}
        application={props.application}
        onHide={e => onViewQuote()}
      />

      <SendToCustomer
        show={showSendToCustomerModal}
        quotes={selectedQuotes}
        application={props.application}
        onHide={e => setShowSendToCustomerModal(false)}
        onSuccess={handleSendQuotes}
        loading={sendLoading}
      />
    </div>
  )
}

export default PreQualifiedQuotes;
