import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

import { resetPassword } from "services";

const ForgotPasswordPage = (props) => {
  const [ email, setEmail ] = useState("");
  const [ success, setSuccess ] = useState(false);

  const handleSubmit = () => {
    if (email) {
        resetPassword({ username: email })
          .then(res => {
            setSuccess(true);
          });
    }
  }

  return (
    <>
      <h3>Forgot your password</h3>

      <div className="form-container mt-auto">
        { success ? (
          <Alert variant="success">
            <p>We've sent a reset password link to your email. Click on the link to reset your password.</p>

            <p>
              Remembered your password? <Alert.Link href="/">Click here</Alert.Link> to return to the login page.
            </p>
          </Alert>
        ) : (
          <>
            <div className="form-item">
              <label>Email address</label>
              <input
                type="text"
                name="email"
                placeholder="Enter your email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>

            <button
              onClick={handleSubmit}
              className="primary"
            >
              Send reset link
            </button>

            <Link to="/" className="form-link">
              Remembered it? Login here
            </Link>
          </>
        )}
      </div>
    </>
  )
}

export default ForgotPasswordPage;
