import axios from "./base";

export const getUsersList = () => {
  return axios.get('/admin/users').then(res => res.data);
}

export const impersonateUser = (params) => {
  return axios.post("/admin/impersonate", params).then(res => res.data);
}

export const getDealersList = () => {
  return axios.get('/admin/dealers?admin=true').then(res => res.data);
}

export const createDealer = (formData) => {
  return axios.post('/admin/dealers', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then(res => res.data);
}

export const updateDealer = (id, formData) => {
  formData.append('_method', 'put');

  return axios.post(`/admin/dealers/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then(res => res.data);
}

export const deleteDealer = (dealer) => {
  return axios.post(`/admin/dealers/${dealer.id}`, { _method: "DELETE" }).then(res => res.data);
}

export const getPolicies = () => {
  return axios.get('/admin/policies').then(res => res.data);
}

export const updatePolicy = (id, formData) => {
  formData.append('_method', 'put');

  return axios.post(`/admin/policies/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then(res => res.data);
}

export const getComplianceQuestions = (params) => {
  return axios.get('/admin/compliance', { params }).then(res => res.data);
}

export const saveComplianceQuestion = (params) => {
  return axios.post('/admin/compliance', params).then(res => res.data);
}

export const deleteComplianceQuestion = (question) => {
  return axios.post(`/admin/compliance/${question.id}`, { _method: "DELETE" }).then(res => res.data);
}
