import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment";

import { getCustomerHistory } from "services";

const CustomerHistory = (props) => {
  const [ events, setEvents ] = useState([]);

  useEffect(() => {
    if (props?.application?.applicationGuid) {
      getCustomerHistory({ applicationGuid: props.application?.applicationGuid })
        .then(res => {
          let records = res.sort((a, b) => new Date(b.createDate) - new Date(a.createDate));

          setEvents(records);
        });
    }
  }, []);

  return (
    <div className="simple-responsive-table">
      <Row className="table-header">
        <Col md={3}>
          Date
        </Col>
        <Col md={3}>
          Type
        </Col>
        <Col md={6}>
          Message
        </Col>
      </Row>

      { events.map((event, index) => (
        <Row className="table-row" key={index}>
          <Col xs={6} md={3}>
            <label className="d-block d-md-none">Date</label>
            { moment(event.createDate).format("DD-MMM-YY HH:mm") }
          </Col>
          <Col xs={6} md={3}>
            <label className="d-block d-md-none">Type</label>
            { event.noteType }
          </Col>
          <Col xs={12} md={6}>
            <label className="d-block d-md-none">Message</label>
            { event.note }
          </Col>
        </Row>
      ))}
    </div>
  );
}

export default CustomerHistory;
