import lenderTypes from "store/types/lenders";

export const setLender = (lender) => ({
  type: lenderTypes.SET_LENDER,
  payload: {
    lender
  }
});

export const setFrequency = (freq) => ({
  type: lenderTypes.SET_FREQUENCY,
  payload: freq,
})

export const setMetaData = (metadata) => ({
  type: lenderTypes.SET_METADATA,
  payload: metadata,
})

export const setCurrentQuote = (quote) => ({
  type: lenderTypes.SET_CURRENT_QUOTE,
  payload: quote,
});
