import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Modal, Spinner } from "react-bootstrap";
import * as yup from "yup";

import { getErrorLabels } from "helpers/errors";
import Error from "components/Error";

const ComplianceQuestionModal = (props) => {
  const [ errors, setErrors ] = useState({});
  const [ formData, setFormData ] = useState({});
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    setFormData(props.question);
  }, [props.question]);

  const schema = yup.object({
    lender_guid: yup.mixed().nullable().oneOf(props.lenders.map(lender => lender.value)),
    product_type: yup.mixed().nullable().oneOf(['pcp', 'hp']),
    question: yup.string().required(),
    order: yup.number().positive().required(),
  });  

  const handleConfirm = () => {
    schema.validate(formData, { abortEarly: false })
      .then(res => {
        setLoading(true);
        props.onConfirm(formData);
        setLoading(false);
      })
      .catch(err => {
        setErrors(getErrorLabels(err));
      });
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <Modal show={props.show} onHide={props.onCancel} className="confirm-modal policy-form-modal">
      <Modal.Header closeButton>
        <Modal.Title>Manage Compliance Question</Modal.Title>
      </Modal.Header>
      <Modal.Body className="policy-form form-container">
        <Row>
          <Col xs={12} className="form-item">
            <label>Lender</label>
            <div>
              <Form.Select name="lender_guid" onChange={handleChange} value={formData?.lender_guid}>
                <option value={null}>Default</option>
                { props.lenders.map(lender => (
                  <option key={lender.value} value={ lender.value }>{ lender.label }</option>
                ))}
              </Form.Select>
              <Error name="lender_guid" errors={errors} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="form-item">
            <label>Property Type</label>
            <div>
              <Form.Select name="product_type" onChange={handleChange} value={formData?.product_type}>
                <option value={null}>Default</option>
                <option value="pcp">PCP</option>
                <option value="hp">HP</option>
              </Form.Select>
              <Error name="product_type" errors={errors} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="form-item">
            <label>Title</label>
            <div>
              <input type="text" name="title" className="w-100" rows={4} style={{ resize: "none" }} onChange={handleChange} value={formData?.title} />
              <Error name="title" errors={errors} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="form-item">
            <label>Question</label>
            <div>
              <textarea name="question" className="w-100" rows={4} style={{ resize: "none" }} onChange={handleChange} value={formData?.question} />
              <Error name="question" errors={errors} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="form-item">
            <label>Position</label>
            <div>
              <input type="number" name="order" className="w-100" onChange={handleChange} value={formData?.order} />
              <Error name="order" errors={errors} />
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="grey"
          onClick={props.onCancel}
        >
          Cancel
        </button>
        <button
          className="dark"
          onClick={handleConfirm}
        >
          { loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>Confirm</>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ComplianceQuestionModal;
