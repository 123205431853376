import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { getUsersList, impersonateUser } from "services/admin";
import { loginSuccessful } from "store/actions/auth";

const UsersListPage = (props) => {
  const [ loading, setLoading ] = useState(false);
  const [ users, setUsers ] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    getUsersList()
      .then(res => {
        setUsers(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleLogin = (user) => {
    impersonateUser({ username: user.username })
      .then(res => {
        dispatch(loginSuccessful(res));
      });
  }

  return (
    <>
      <div className="page-top">
        <h3 className="page-title">Admin Dashboard</h3>
      </div>

      <div className="mt-5">
        <table className="table table-bordered text-center" style={{ width: "auto", minWidth: "50%" }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            { users.map((user, index) => (
              <tr key={index}>
                <td className="align-middle">{ user.firstName } { user.surname }</td>
                <td className="align-middle">{ user.username }</td>
                <td>
                  <button
                    onClick={e => handleLogin(user)}
                    className="green"
                  >
                    { loading ? (
                      <Spinner animation="border" variant="dark" size="sm" />
                    ) : (
                      <>Login</>
                    )}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default UsersListPage;
