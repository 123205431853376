import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";

import { getApplicationUpdateObject, getBlankApplication } from "helpers/application";
import { getErrorLabels } from "helpers/errors";
import Error from "components/Error";

const DrivingInfo = (props) => {
  const [ drivingLicense, setDrivingLicense ] = useState("");
  const [ errors, setErrors ] = useState({});
  const [ vehicleUse, setVehicleUse ] = useState("");

  const metaData = useSelector(state => state?.lender.metaData);

  useEffect(() => {
    if (props.application?.client?.drivingLicenceType) {
      setDrivingLicense(props.application?.client?.drivingLicenceType);
    }

    if (props?.application?.vehicle?.vehcileUse) {
      setVehicleUse(props?.application?.vehicle?.vehcileUse);
    }
  }, [props.application]);

  const validationSchema = yup.object({
    drivingLicense: yup.mixed().oneOf(props?.schema?.properties?.client?.properties?.licenceType?.enum ?? []).required(),
    vehicleUse: yup.mixed().oneOf(props?.schema.properties?.vehicle?.properties?.vehicleUse?.enum ?? []).required(),
  });

  const getLicenseTypeOptions = () => {
    if (metaData?.drivingLicenseTypeList) {
      return metaData?.drivingLicenseTypeList;
    }
    return [];
  }

  const getVehicleUseOptions = () => {
    if (metaData?.vehicleUseList) {
      return metaData?.vehicleUseList;
    }   
    return [];
  }

  const updateApplication = () => {
    let applicationObj;

    validationSchema.validate({
      drivingLicense,
      vehicleUse,
    }, { abortEarly: false })
      .then(() => {
        if (props?.application?.applicationGuid) {
          applicationObj = getApplicationUpdateObject(props.application);

          props.onUpdate({
            client: {
              ...applicationObj?.client,
              liscenceType: drivingLicense,
            },
            vehicle: {
              ...applicationObj?.vehicle,
              vehcileUse: vehicleUse,
            },
          });
        } else {
          applicationObj = getBlankApplication(props.application);

          props.onUpdate({
            client: {
            ...applicationObj?.client,
            drivingLicenceType: drivingLicense,
          },
          vehicle: {
            ...applicationObj?.vehicle,
            vehcileUse: vehicleUse,
          },
        });
      }

      props.changeTab("legals");
    }).catch(err => {
      setErrors(getErrorLabels(err));
    });
  }

  return (
    <div className="customer-details">
      <Row>
        <Col xs={12}>
          <label>Does the customer hold a full uk licence?</label>
          <div>
            <Form.Select value={drivingLicense} onChange={e => setDrivingLicense(e.target.value)}>
              <option value=""></option>
              { getLicenseTypeOptions().map(option => (
                <option value={option} key={option}>{ option }</option>
              ))}
            </Form.Select>
            <Error name="drivingLicense" errors={errors} />
          </div>
        </Col>
      </Row>
      {/*
      <Row>
        <Col xs={12}>
          <label>How long has the customer held their licence for?</label>
          <div></div>
        </Col>
      </Row>
      */}
      <Row>
        <Col xs={12}>
          <label>What's the intended use of the vehicle?</label>
            <Form.Select value={vehicleUse} onChange={e => setVehicleUse(e.target.value)}>
              <option value=""></option>
              { getVehicleUseOptions().map(option => (
                <option value={option} key={option}>{ option }</option>
              ))}
            </Form.Select>
            <Error name="vehicleUse" errors={errors} />
        </Col>
      </Row>
      <Row className="align-items-end mb-0">
        <Col xs={12} className="d-flex">
          <button
            onClick={updateApplication}
            className="dark ms-auto"
          >
            Save customer & continue
          </button>
        </Col>
      </Row>
    </div>    
  );
}

export default DrivingInfo;
