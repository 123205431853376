import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import loginBackground from "assets/images/login-background-cropped.png";
import logo from "assets/images/logo.png";

const GuestLayout = (props) => {
  return (
    <div className="guest-layout">
      <div className="content-container">
        <div className="content">
          <Container fluid>
            <Row>
              <Col xs={12} lg={6} className="left-content">
                <div className="logo">
                  <img src={logo} alt="logo" />
                </div>
                {props.children}

                <div className="copyright">
                  All rights reserved. Fastcheck 2024.
                </div>
              </Col>
              <Col lg={6} className="right-content d-none d-lg-block">
                <div className="image-text">
                  <h3>Fastcheck it out.</h3>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default GuestLayout;
