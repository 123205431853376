import React from "react";

const ApplicationStatus = (props) => {
  return (
    <div className={`application-status ${props.status.toLowerCase().replace(/[^a-z0-9]/, '-')}`}>
      { props.status }
    </div>
  )
}

export default ApplicationStatus;
