import React from "react";
import { Modal } from "react-bootstrap";

import { ReactComponent as ApplicationReferredIcon } from "assets/images/application-referred.svg";

const ApplicationReferred = (props) => {
  return (
    <>
      <Modal.Header closeButton>
        Lender decision
      </Modal.Header>
      <Modal.Body className="lender-decision-container">
        <div className="lender-decision">
          <ApplicationReferredIcon />

          <h3 className="section-title">The finance application has been referred.</h3>

          <p className="info">The lender will be in touch with the customer and/or yourself shortly with further questions regarding the application.</p>
        </div>
      </Modal.Body>
    </>
  );
}

export default ApplicationReferred;
