import React from "react";

const Error = (props) => {

  const getError = () => {
    if (Object.keys(props.errors).includes(props.name)) {
      return props.errors[props.name];
    }

    return null;
  }

  if (!props.name || Object.keys(props.errors).length == 0) {
    return null;
  }

  return (
    <div className="form-error">
      { getError() }
    </div>
  )
}

Error.defaultProps = {
  name: null,
  errors: {},
}

export default Error;
