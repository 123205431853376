import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";

import { changePassword } from "services";

const ResetPasswordPage = (props) => {
  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ formData, setFormData ] = useState({
    password: undefined,
    confirm: undefined,
  });

  const { token } = useParams();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  const handleResetPassword = () => {
    if (formData.password && formData.password === formData.confirm) {
      changePassword({ token, password: formData.password })
        .then(res => {
          navigate("/");
        })
        .catch(err => {
          if (err.response) {
            if (err.response.status === 403) {
              setErrorMessage('Password is too weak. Please try a stronger password');
            } else {
              setErrorMessage('Unable to process request. Please request a new reset password link');
            }
          }
        });
    } else {
      setErrorMessage("Please ensure a new password has been provided and both password fields match");
    }
  }

  return (
    <>
      <h3>Choose new password</h3>

      { errorMessage && (
        <Alert variant="danger" className="mt-4 text-center">
          { errorMessage }
        </Alert>
      )}

      <div className={`form-container ${errorMessage ? "mt-4" : "mt-auto"}`}>
        <div className="form-item">
          <label>Enter your new password</label>
          <input
            type="password"
            name="password"
            placeholder="Enter new password"
            onChange={handleChange}
          />

          <input
            type="password"
            name="confirm"
            placeholder="Confirm new password"
            onChange={handleChange}
          />
        </div>

        <button
          onClick={handleResetPassword}
          className="primary"
        >
          Change password
        </button>
      </div>
    </>
  )
}

export default ResetPasswordPage;
