import CreateQuotePage from "pages/user/applications/CreateQuotePage";
import ApplicationInfoPage from "pages/user/applications/ApplicationInfoPage";
import ApplicationListPage from "pages/user/applications/ApplicationListPage";
import DashboardPage from "pages/user/DashboardPage";
import LendersListPage from "pages/user/lenders/LendersListPage";

const routes = [
  {
    name: "dashboard",
    path: "/",
    component: <DashboardPage />,
  },
  {
    name: "lenders",
    path: "/lenders",
    component: <LendersListPage />,
  },
  {
    name: "customers",
    path: "/customers",
    component: <ApplicationListPage />,
  },
  {
    name: "customer",
    path: "/customers/:guid",
    component: <ApplicationInfoPage />,
  },
  {
    name: "new-quote",
    path: "/quotes/new",
    component: <CreateQuotePage />,
  }
];

export default routes;
