import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getApplication, getApplicationSchema, getClientPreQualifiedQuotes, updateApplication } from "services";
import { getApplicationUpdateObject } from "helpers/application";
import { setPageLoading, setPageLoadingMsg } from "store/actions/auth";
import { setCurrentQuote } from "store/actions/lenders";
import ApplicationInformationForm from "components/form/application/ApplicationInformationForm";
import QuoteBuilder from "components/QuoteBuilder";

const ApplicationInfoPage = (props) => {
  const { guid } = useParams();

  const dispatch = useDispatch();

  const [ activeTab, setActiveTab ] = useState("prequalified");
  const [ application, setApplication ] = useState({});
  const [ applicationSchema, setApplicationSchema ] = useState({});
  const [ quoteHasChanged, setQuoteHasChanged ] = useState(false);
  const [ quotes, setQuotes ] = useState([]);
  const [ quoteSortBy, setQuoteSortBy ] = useState("payments");

  const currentQuote = useSelector(state => state?.lender?.currentQuote);

  useEffect(() => {
    dispatch(setPageLoadingMsg("We are retrieving the latest quotes from the lenders. This could take up to 30 seconds, thank you for your patience."));
    dispatch(setPageLoading(true));

    getApplication({ applicationGuid: guid })
      .then(res => {
        setApplication(res);
      });
      
    getApplicationSchema()
      .then(res => {
        setApplicationSchema(res);
      })

    if (!currentQuote || currentQuote?.applicationGuid !== guid) {
      getClientPreQualifiedQuotes({ applicationGuid: guid })
        .then(res => {
          setQuotes(res);
          dispatch(setPageLoading(false));
        });
    } else {
      setQuotes(currentQuote);
      dispatch(setPageLoading(false));
      dispatch(setCurrentQuote(null));
    }
  }, [guid]);

  const handleQuoteSort = (field) => {
    setQuoteSortBy(field);
    let localQuotes = [ ...quotes.lenderList ];

    localQuotes.sort((a, b) => {
      return a.quoteList[0][field] - b.quoteList[0][field];
    });

    setQuotes({
      ...quotes,
      lenderList: localQuotes
    });
  }

  const handleApplicationUpdate = (values, pxChanged = false) => {
    let updateApplicationObj = getApplicationUpdateObject(application);

    updateApplicationObj = {
      ...updateApplicationObj,
      ...values,
    };

    updateApplication(updateApplicationObj)
      .then(res => {
        setApplication(res);

        if (pxChanged) {
          setQuoteHasChanged(true);
        }
      });
  }

  if (!application || guid !== application.applicationGuid) {
    return null;
  }

  return (
    <div className="application-info-page">
      <Row className="d-none d-md-flex mt-5 application-info-header">
        <Col md={3}>
          Customer: <span>{ application?.client?.firstName } { application?.client?.lastName }</span>
        </Col>
        <Col md={3}>
          Telephone: <span>{ application?.client?.clientCellNumber }</span>
        </Col>
        <Col md={3}>
          Status: <span>{ application?.submissionStatus }</span>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12} lg={8} style={{ position: "relative" }}>
          <ApplicationInformationForm
            application={application}
            schema={applicationSchema}
            onUpdate={handleApplicationUpdate}
            setApplication={setApplication}
            quotes={quotes}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            quoteChanged={quoteHasChanged}
            sortBy={quoteSortBy}
            onSortBy={handleQuoteSort}
          />

          { quoteHasChanged && (
            <div className="quote-change-overlay">
              <div className="quote-changed-content">
                You have changed this quote. Please click the 'Recalculate quote' button to view your updated quotes.
              </div>
            </div>
          )}
        </Col>
        <Col lg={4}>
          <QuoteBuilder
            application={application}
            setQuotes={setQuotes}
            setActiveTab={setActiveTab}
            onQuoteChange={value => setQuoteHasChanged(value)}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ApplicationInfoPage;
