import React from "react";
import { Col, Row } from "react-bootstrap";

const EnquirySource = (props) => {
  return (
    <div className="customer-details">
      <Row>
        <Col xs={12}>
          <label>Source of enquiry</label>
          <div>{ props.application?.enquirySource ?? "N/A" }</div>
        </Col>
      </Row>
    </div>
  );
}

export default EnquirySource;
