import React from "react";

const LenderDecision = (props) => {
  return (
    <div className={`lender-decision ${props.status.toLowerCase().replace(/[^a-z0-9]/, '_')} ${props.rag.toLowerCase()}`}>
      { props.status }
    </div>
  )
}

LenderDecision.defaultProps = {
  rag: "",
}

export default LenderDecision;
