import { useEffect } from "react";
import { Navigate, Routes, Route, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { adminRoutes, guestRoutes, userRoutes } from "routes";
import GuestLayout from "components/layouts/Guest";
import UserLayout from "components/layouts/User";

const App = () => {
  const isAdmin = useSelector(state => state?.auth?.isAdmin);
  const token = useSelector(state => state?.auth?.token);

  const navigate = useNavigate();

  const [ searchParams ] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('redirect')) {
      navigate(searchParams.get('redirect'));
    }
  }, [token]);

  return (
    <>
      { token ? (
        <UserLayout>
          <Routes>
            { isAdmin ? (
              <>
                { adminRoutes.map(route => (
                  <Route path={route.path} element={route.component} key={route.name} />
                ))}
                <Route path="*" element={<Navigate to="/" replace />} />
              </>
            ) : (
              <>
                { userRoutes.map(route => (
                  <Route path={route.path} element={route.component} key={route.name} />
                ))}
                <Route path="*" element={<Navigate to="/" replace />} />
              </>
            )}
          </Routes>
        </UserLayout>
      ) : (
        <GuestLayout>
          <Routes>
            { guestRoutes.map(route => (
              <Route path={route.path} element={route.component} key={route.name} />
            ))}
            <Route path="*" element={<Navigate to={`/?redirect=${window.location.pathname}`} replace />} />
          </Routes>
        </GuestLayout>
      )}
    </>
  );
}

export default App;
