import { combineReducers } from "redux";
import { authReducer } from "store/reducers/auth";
import { lenderReducer } from "store/reducers/lenders";

const rootReducer = combineReducers({
  auth: authReducer,
  lender: lenderReducer,
});

export default rootReducer;
