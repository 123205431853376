import React from "react";

const Error = (props) => {
  if (!props.error) {
    return null;
  }

  return (
    <div className="form-errors">
      <div className="form-error">{ props.error }</div>
    </div>
  )
}

export default Error;
