export const getVehicleName = (applicationVehicle) => {
  let vehicle = [];

  if (applicationVehicle?.make) {
    vehicle.push(applicationVehicle.make);
  }

  if (applicationVehicle?.model) {
    vehicle.push(applicationVehicle.model);
  }

  if (applicationVehicle?.modelYear) {
    vehicle.push(applicationVehicle.modelYear);
  }

  return vehicle.join(" ");
}
