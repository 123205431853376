import axios from "axios";
import store from "store";

import { logout } from "store/actions/auth";

const configs = {
  baseURL: `${process.env.REACT_APP_API_BASE}`,
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: (status) => {
    return status >= 200 && status < 400;
  },
}

const getFromStore = () => {
  const state = store.getState();

  return {
    token: state?.auth?.token ? `Bearer ${state.auth?.token}` : ''
  }
}

const handleError = error => {
  return Promise.reject(error);
}

const api = axios.create(configs);

api.interceptors.request.use(config => {
  const { token } = getFromStore();

  config.headers = { ...config.headers, authorization: token };

  return config;
}, handleError);

api.interceptors.response.use(
  response => response,
  async error => {
    if (error?.response?.status === 401) {
      await store.dispatch(logout());
    }

    return Promise.reject(error);
  }
);

export default api;
