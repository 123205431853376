import React from "react";

import logoIcon from "assets/images/logo.png";

const Footer = (props) => {
  return (
    <footer>
      <img src={logoIcon} alt="logo" />
    </footer>
  )
}

export default Footer;
