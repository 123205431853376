import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";

const ConfirmModal = (props) => {
  const [ loading, setLoading ] = useState(false);

  const handleConfirm = () => {
    setLoading(true);
    props.onConfirm();
    setLoading(false);
  }

  return (
    <Modal show={props.show} onHide={props.onCancel} className="confirm-modal">
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { props.text ?? "Are you sure?" }
      </Modal.Body>
      <Modal.Footer>
        <button
          className="grey"
          onClick={props.onCancel}
        >
          Cancel
        </button>
        <button
          className="dark"
          onClick={handleConfirm}
        >
          { loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>Confirm</>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
