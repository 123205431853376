import React from "react";
import { Modal, Spinner } from "react-bootstrap";

const QuoteTermsModal = (props) => {
  return (
    <div
      className={`modal static ${props.show ? "show" : ""}`}
    >
      <Modal.Dialog>
        <Modal.Body>
          { props.showTermWarning && (
            <p>
              Increasing the term of your finance agreement will reduce your 
              monthly payments and may result in you paying more interest 
              over the increased term.
            </p>
          )}

          { props.showMileageWarning && (
            <p>
              Please ensure you select the mileage you require. 
              If you exceed your proposed mileage you may be liable 
              for an excess mileage charge at the end of the finance 
              agreement. This will affect PCP products only.
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            disabled={props.loading}
            onClick={props.onAccept}
            className="green w-100"
          >
            { props.loading ? (
              <Spinner animation="border" variant="dark" size="sm" />
            ) : (
              <>Customer accepts</>
            )}
          </button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
}

QuoteTermsModal.defaultProps = {
  show: false,
  showTermWarning: false,
  showMileageWarning: false,
  onHide: () => {},
  onAccept: () => {},
}

export default QuoteTermsModal;
