import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";

import { deleteComplianceQuestion, getComplianceQuestions, saveComplianceQuestion } from "services";
import ComplianceQuestionModal from "components/modals/ComplianceQuestionModal";
import ConfirmModal from "components/modals/ConfirmModal";
import SelectInput from "components/form/SelectInput";

import { ReactComponent as DeleteIcon } from "assets/images/delete.svg";
import { ReactComponent as EditIcon } from "assets/images/edit.svg";

const CompliancePage = (props) => {
  const [ lenderFilter, setLenderFilter ] = useState(null);
  const [ lenderOptions, setLenderOptions ] = useState([]);
  const [ lenders, setLenders ] = useState([]);
  const [ productFilter, setProductFilter ] = useState(null);
  const [ questions, setQuestions ] = useState([]);
  const [ showConfirm, setShowConfirm ] = useState(null);
  const [ showQuestionModal, setShowQuestionModal ] = useState(false);

  useEffect(() => {
    getQuestions();
  }, []);

  useEffect(() => {
    getQuestions();
  }, [productFilter, lenderFilter]);

  const getQuestions = () => {
    getComplianceQuestions({ product: productFilter, lender: lenderFilter })
      .then(res => {
        setLenders(res.lenders);
        setQuestions(res.questions);

        let lenderOptions = res.lenders.map(lender => {
          return {
            label: lender.lenderName,
            value: lender.lenderGuid,
          };
        });

        setLenderOptions(lenderOptions);
      });
  }

  const getLenderName = (guid) => {
    if (!guid) {
      return 'DEFAULT';
    }

    let lender = lenders.find(lender => lender.lenderGuid === guid);

    return lender?.lenderName;
  }

  const handleQuestionConfirm = (values) => {
    saveComplianceQuestion(values)
      .then(res => {
        setShowQuestionModal(false);
        getQuestions();
      });
  }

  const handleDeleteQuestion = () => {
    deleteComplianceQuestion(showConfirm)
      .then(res => {
        let localQuestions = [...questions].filter(question => question.id !== showConfirm.id);
        setQuestions(localQuestions);

        setShowConfirm(null);
      });
  }

  return (
    <div className="applications-list-page">
      <div className="page-top">
        <div className="page-top-title-logo">
          <h3 className="page-title">Compliance Questions</h3>
        </div>

        <div className="page-top-actions">
          <div>
            <SelectInput
              value={lenderFilter}
              options={lenderOptions}
              onChange={setLenderFilter}
              label="Lender"
              allLabel="All lenders"
            />
          </div>
          <div>
            <SelectInput
              value={productFilter}
              options={[
                {
                  label: "PCP",
                  value: "pcp"
                },
                {
                  label: "HP",
                  value: "hp"
                },
              ]}
              onChange={setProductFilter}
              label="Product type"
              allLabel="All product types"
            />
          </div>
        </div>
      </div>

      <div className="application-content mt-5">
        <div className="d-flex justify-content-end mb-2">
          <button className="green" onClick={e => setShowQuestionModal(true)}>Add New</button>
        </div>

        <table className="table table-bordered text-center w-100 align-middle">
          <thead>
            <tr>
              <th>Lender</th>
              <th>Product Type</th>
              <th>Title</th>
              <th>Question</th>
              <th>Order</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            { questions.map(question => (
              <tr>
                <td>{ getLenderName(question.lender_guid) }</td>
                <td style={{ textTransform: "uppercase" }}>{ question.product_type ?? 'Default' }</td>
                <td>{ question.title }</td>
                <td>{ question.question.substr(0, 100) }</td>
                <td>{ question.order }</td>
                <td>
                  <button
                    onClick={e => setShowQuestionModal(question)}
                    className="link"
                  >
                    <EditIcon width={18} height={18} />
                  </button>

                  <button
                    className="link"
                    onClick={e => setShowConfirm(question)}
                  >
                    <DeleteIcon width={18} height={18} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <ComplianceQuestionModal
        show={showQuestionModal !== false}
        question={showQuestionModal}
        lenders={lenderOptions}
        onCancel={() => setShowQuestionModal(false)}
        onConfirm={handleQuestionConfirm}
      />

      <ConfirmModal
        show={showConfirm !== null}
        onCancel={() => setShowConfirm(null)}
        onConfirm={handleDeleteQuestion}
        text="Are you sure you wish to delete this compliance question?"
      />
    </div>
  );
}

export default CompliancePage;
