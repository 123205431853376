import ForgotPasswordPage from "pages/guest/ForgotPasswordPage";
import LoginPage from "pages/guest/LoginPage";
import ResetPasswordPage from "pages/guest/ResetPasswordPage";

const guestRoutes = [
  {
    name: "login",
    path: "/",
    component: <LoginPage />,
  },
  {
    name: "forgot",
    path: "/forgot",
    component: <ForgotPasswordPage />,
  },
  {
    name: "reset",
    path: "/reset/:token",
    component: <ResetPasswordPage />,
  }
];

export default guestRoutes;
