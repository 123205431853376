import moment from "moment";

export const getApplicationUpdateObject = (application) => {
  let currentAddress = application?.client?.addressList[0] ?? null;
  let previousAddress = application?.client?.addressList[1] ?? null;

  let yearsAtJob = (application?.client?.monthsAtJob ? Math.floor(application?.client?.monthsAtJob / 12) : 0);
  let monthsAtJob = (application?.client?.monthsAtJob ? (application?.client?.monthsAtJob % 12) : 0);

  let yearsAtAddress = (currentAddress?.monthsAtAddress ? Math.floor(currentAddress?.monthsAtAddress / 12) : 0);
  let monthsAtAddress = (currentAddress?.monthsAtAddress ? (currentAddress?.monthsAtAddress % 12) : 0);

  let previousAddressObj = {};
  if (previousAddress) {
    let previousYearsAtAddress = (previousAddress?.monthsAtAddress ? Math.floor(previousAddress?.monthsAtAddress / 12) : 0);
    let previousMonthsAtAddress = (previousAddress?.monthsAtAddress ? (previousAddress?.monthsAtAddress % 12) : 0);

    previousAddressObj = {
      previousAddress: {
        streetNumber: previousAddress?.addressLine1,
        houseName: "",
        monthsAtAddress: previousMonthsAtAddress,
        yearsAtAddress: previousYearsAtAddress,
        postcode: previousAddress?.postCode,
        streetName: previousAddress?.addressLine2,
      }
    }
  }

  let pxVehicle = {}
  if (application?.pxVehcile) {
    pxVehicle = {
      pxVehcile: {
        mileage: application?.pxMileage,
        outstandingFinance: application?.outstandingFinanceValue,
        partExValuation: application?.estimatedValue,
        pxNotes: application?.pxVehcile?.notes,
        pxVehicleRegistrationNumber: application?.pxVehcile?.registrationNumber,
      }
    };
  }

  let updateObj = {
    applicationGuid: application.applicationGuid,
    dealerAPIKey: application.dealerGuid,
    //acceptInitialDisclosure: application.acceptInitialDisclosure,
    //acceptPrivacyPolicy: application.acceptPrivacyPolicy,
    //acceptTAndCDate: application.acceptTAndCDate,
    annualMileage: application.annualMileage,
    client: {
      cellNumber: application?.client?.clientCellNumber,
      clientEmail: application?.client?.clientEmail,
      currentAddress: {
        streetNumber: currentAddress?.addressLine1,
        monthsAtAddress,
        yearsAtAddress,
        postcode: currentAddress?.postCode,
        streetName: currentAddress?.addressLine2,
      },
      dateOfBirth: `${application?.client?.dateOfBirth} 00:00:00`,
      occupationStatus: application?.client?.occupationStatus,
      employmentStatus: application?.client?.employmentStatus,
      firstName: application?.client?.firstName,
      income: application?.client?.income,
      lastName: application?.client.lastName,
      liscenceType: application?.client?.drivingLicenceType,
      maritalStatus: application?.client?.maritalStatus,
      monthsAtJob,
      title: application?.client?.title,
      typeOfResidence: application?.client?.typeOfResidence,
      yearsAtJob,
      ...previousAddressObj,
    },
    deposit: application.deposit,
    pxDeposite: application.pxDeposite,
    ...pxVehicle,
    suppliedClientIDs: [],
    term: application?.term,
    vehicle: {
      colour: application?.vehicle?.colour,
      engineCapacity: application?.vehicle?.engineCapacity,
      engineNo: application?.vehicle?.engineNo,
      fuel: application?.vehicle?.fuel,
      make: application?.vehicle?.make,
      mileage: application?.vehicle?.mileage,
      model: application?.vehicle?.model,
      modelYear: application?.vehicle?.modelYear,
      registrationNumber: application?.vehicle?.registrationNumber,
      salesValue: application?.vehicle?.salesValue ?? 0,
      transmission: application?.vehicle?.transmission,
      vehcileType: application?.vehicle?.vehcileType,
      vehcileUse: application?.vehicle?.vehcileUse,
      vehicleImage: application?.vehicle?.vehcileImage,
      vin: application?.vehicle?.vin,
    },
  }

  if (updateObj.client.occupationStatus === null) {
    delete updateObj.client.occupationStatus;
  }

  if (updateObj.client.employmentStatus === null) {
    delete updateObj.client.employmentStatus;
  }

  return updateObj;
}

export const getApplicationCreateObject = (application, dealerGuid = null) => {
  let currentAddress = application?.client?.addressList[0] ?? null;
  let previousAddress = application?.client?.addressList[1] ?? null;
  let yearsAtJob = (application?.client?.monthsAtJob ? Math.floor(application?.client?.monthsAtJob / 12) : 0);
  let monthsAtJob = (application?.client?.monthsAtJob ? (application?.client?.monthsAtJob % 12) : 0);
    
  let yearsAtAddress = (currentAddress?.monthsAtAddress ? Math.floor(currentAddress?.monthsAtAddress / 12) : 0);
  let monthsAtAddress = (currentAddress?.monthsAtAddress ? (currentAddress?.monthsAtAddress % 12) : 0);

  let previousAddressObj = {};
  if (previousAddress) {
    let previousYearsAtAddress = (previousAddress?.monthsAtAddress ? Math.floor(previousAddress?.monthsAtAddress / 12) : 0);
    let previousMonthsAtAddress = (previousAddress?.monthsAtAddress ? (previousAddress?.monthsAtAddress % 12) : 0);

    previousAddressObj = {
      previousAddress: {
        streetNumber: previousAddress?.addressLine1,
        houseName: "",
        monthsAtAddress: previousMonthsAtAddress,
        yearsAtAddress: previousYearsAtAddress,
        postcode: previousAddress?.postCode,
        streetName: previousAddress?.addressLine2,
      }
    }
  }

  let pxVehicle = {};
  if (application?.pxVehcile?.registrationNumber) {
    pxVehicle = {
      pxVehicle: {
        mileage: application?.pxMileage,
        outstandingFinance: application?.outstandingFinanceValue,
        partExValuation: application?.estimatedValue,
        pxNotes: application?.pxVehcile?.notes,
        pxVehicleRegistrationNumber: application?.pxVehcile?.registrationNumber,
      }
    }
  }
    
  return {
    dealerAPIKey: dealerGuid,
    acceptInitialDisclosure: moment(application.acceptInitialDisclosure).format("YYYY-MM-DD HH:mm:ss"),
    acceptPrivacyPolicy: moment(application.acceptPrivacyPolicy).format("YYYY-MM-DD HH:mm:ss"),
    acceptTAndCDate: moment(application.acceptTAndCDate).format("YYYY-MM-DD HH:mm:ss"),
    annualMileage: application.annualMileage,
    client: {
      cellNumber: application?.client?.clientCellNumber,
      clientEmail: application?.client?.clientEmail,
      currentAddress: {
        streetNumber: currentAddress?.addressLine1,
        houseName: "",
        monthsAtAddress,
        yearsAtAddress,
        postcode: currentAddress?.postCode,
        streetName: currentAddress?.addressLine2,
      },
      dateOfBirth: `${application?.client?.dateOfBirth} 00:00:00`,
      occupationStatus: application?.client?.occupationStatus,
      employmentStatus: application?.client?.employmentStatus,
      firstName: application?.client?.firstName,
      income: application?.client?.income,
      lastName: application?.client.lastName,
      licenceType: application?.client?.drivingLicenceType,
      maritalStatus: application?.client?.maritalStatus,
      monthsAtJob, 
      title: application?.client?.title,
      typeOfResidence: application?.client?.typeOfResidence,
      yearsAtJob,
      ...previousAddressObj,
    },    
    deposit: application.deposit,
    pxDeposit: application.pxDeposite,
    ...pxVehicle,
    enquirySource: "Showroom",
    //suppliedClientIDs: [],
    term: application?.term,
    vehicle: {
      //colour: application?.vehicle?.colour,
      //engineCapacity: application?.vehicle?.engineCapacity,
      //engineNo: application?.vehicle?.engineNo,
      //fuel: application?.vehicle?.fuel,
      //make: application?.vehicle?.make,
      mileage: application?.vehicle?.mileage,
      //model: application?.vehicle?.model,
      //modelYear: application?.vehicle?.modelYear,
      registrationNumber: application?.vehicle?.registrationNumber,
      salesValue: application?.vehicle?.salesValue ?? 0,
      //transmission: application?.vehicle?.transmission,
      //vehcileType: application?.vehicle?.vehcileType,
      vehicleUse: application?.vehicle?.vehcileUse,
      //vehicleImage: application?.vehicle?.vehcileImage,
      //vin: application?.vehicle?.vin,
    },
  }
}

export const getBlankApplication = (application) => {
  let previousAddressObj = [];
  if (application?.client?.addressList.length > 1) {
    previousAddressObj = [{
      addressLine1: application?.client?.addressList[1]?.addressLine1,
      addressLine2: application?.client?.addressList[1]?.addressLine2,
      addressLine3: application?.client?.addressList[1]?.addressLine3,
      addressLine4: application?.client?.addressList[1]?.addressLine4,
      addressLine5: application?.client?.addressList[1]?.addressLine5,
      postCode: application?.client?.addressList[1]?.postCode,
      monthsAtAddress: application?.client?.addressList[1]?.monthsAtAddress,
    }];
  }

  return {
    applicationGuid: application?.applicationGuid,
    dealerGuid: application?.dealerGuid,
    equity: application?.equity ?? 0,
    term: application?.term ?? 48,
    annualMileage: application?.annualMileage ?? 10000,
    estimatedValue: application?.estimatedValue ?? 0,
    outstandingFinanceValue: application?.outstandingFinanceValue ?? 0,
    pxMileage: application?.pxMileage ?? 0,
    vehicle: {
      registrationNumber: application?.vehicle?.registrationNumber,
      vin: application?.vehicle?.vin,
      engineNo: application?.vehicle?.engineNo,
      mileage: application?.vehicle?.mileage,
      salesValue: application?.vehicle?.salesValue ?? 0,
      make: application?.vehicle?.make,
      modelYear: application?.vehicle?.modelYear,
      colour: application?.vehicle?.colour,
      model: application?.vehicle?.model,
      vehicleImage: application?.vehicle?.vehicleImage,
      fuel: application?.vehicle?.fuel,
      transmission: application?.vehicle?.transmission,
      engineCapacity: application?.vehicle?.engineCapacity,
      vehcileUse: application?.vehicle?.vehcileUse,
      vehcileType: application?.vehicle?.vehcileType,
    },
    client: {
      firstName: application?.client?.firstName,
      lastName: application?.client?.lastName,
      dateOfBirth: application?.client?.dateOfBirth ?? "",
      addressList: [
        {
          addressLine1: application?.client?.addressList[0]?.addressLine1,
          addressLine2: application?.client?.addressList[0]?.addressLine2,
          addressLine3: application?.client?.addressList[0]?.addressLine3,
          addressLine4: application?.client?.addressList[0]?.addressLine4,
          addressLine5: application?.client?.addressList[0]?.addressLine5,
          postCode: application?.client?.addressList[0]?.postCode,
          monthsAtAddress: application?.client?.addressList[0]?.monthsAtAddress,
        },
        ...previousAddressObj,
      ],
      income: application?.client?.income,
      age: application?.client?.age,
      drivingLicenceType: application?.client?.drivingLicenceType,
      typeOfResidence: application?.client?.typeOfResidence,
      maritalStatus: application?.client?.maritalStatus,
      employmentStatus: application?.client?.employmentStatus,
      title: application?.client?.title,
      occupationStatus: application?.client?.occupationStatus,
      numberOfDependanats: application?.client?.numberOfDependanats,
      employerAddres: application?.client?.employerAddress,
      employerName: application?.client?.employerName,
      nameOnAccount: application?.client?.nameOnAccount,
      sortCode: application?.client?.sortCode,
      accountNumber: application?.client?.accountNumber,
      clientEmail: application?.client?.clientEmail,
      monthsAtJob: application?.client?.monthsAtJob,
      clientCellNumber: application?.client?.clientCellNumber,
      curcumstanceChange: application?.client?.curcumstanceChange,
      replacementLoan: application?.client?.replacementLoan,
      employmentHistory: application?.client?.employmentHistory,
      lenderQuestionAnswers: application?.client?.lenderQuestionAnswers,
    },
    pxVehcile: (application?.pxVehcile ?
      {
        registrationNumber: application?.pxVehcile?.registrationNumber,
        engineCapacity: application?.pxVehcile?.engineCapacity,
        fuelType: application?.pxVehcile?.fuelType,
        colour: application?.pxVehcile?.colour,
        make: application?.pxVehcile?.make,
        yearOfManufacture: application?.pxVehcile?.yearOfManufacture,
        notes: application?.pxVehcile?.notes,
      } :
      null
    ),
    deposit: application?.deposit ?? 0,
    pxDeposite: application?.pxDeposite ?? false,
    applicationStatus: application?.applicationStatus ?? "",
    customerStatus: application?.customerStatus,
    createDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    enquirySource: application?.enquirySource,
    submissionStatus: application?.submissionStatus ?? "Unsubmitted",
    quoteStatus: application?.quoteStatus,
    approvedRagStatus: application?.approvedRagStatus,
    acceptTAndCDate: application?.acceptTAndCDate,
    acceptPrivacyPolicy: application?.acceptPrivacyPolicy,
    acceptInitialDisclosure: application?.acceptInitialDisclosure,
  }
}

export const getYearOptions = () => {
  let years = [];
  for(let i = 0; i <= 40; i++) {
    years.push({ label: `${i} years`, value: i });
  }             
  return years;
}
            
export const getMonthOptions = () => {
  let months = [];
  for(let i = 0; i <= 11; i++) {
    months.push({ label: `${i} months`, value: i });
  }           
  return months;
}
