import axios from "./base";

export const loginUser = (params) => {
  return axios.post('/dealer/authenticate', params).then(res => res.data);
}

export const resetPassword = (params) => {
  return axios.post('/dealer/forgot', params).then(res => res.data);
}

export const changePassword = (params) => {
  return axios.post('/dealer/reset', { _method: 'put', ...params }).then(res => res.data);
}
