import React from "react";

const Pill = (props) => {
  return (
    <div className={`pill-container ${props.enabled ? "enabled" : ""}`}>
      { props.name }
    </div>
  );
}

Pill.defaultProps = {
  name: "",
  enabled: false,
}

export default Pill;
