import React from "react";
import { Modal } from "react-bootstrap";

import { ReactComponent as ApplicationDeclinedIcon } from "assets/images/application-declined.svg";

const ApplicationDeclined = (props) => {
  return (
    <>
      <Modal.Header closeButton>
        Lender decision
      </Modal.Header>
      <Modal.Body className="lender-decision-container">
        <div className="lender-decision">
          <ApplicationDeclinedIcon />

          <h3 className="section-title">The finance application has been declined.</h3>

          <p className="info">The lender has declined the finance application, please go back and try other quotes from different lenders.</p>
        </div>
      </Modal.Body>
    </>
  );
}

export default ApplicationDeclined;
