import React from "react";
import { Link, useLocation } from "react-router-dom";

const MenuLink = (props) => {
  const location = useLocation();

  const renderLink = (
    <>
      {props.icon && <>{props.icon}</>}
      <span>{props.text}</span>

      {props.children}
    </>
  );

  const isSelected = () => {
    return props.href && props.href === location.pathname;
  };

  const handleOnClick = (e) => {
    e.preventDefault();
    if (props.onClick) {
      props.onClick();
    }
    if (props.closeMenu) {
      props.closeMenu();
    }
  };

  return (
    <li className={`${props.liClassName} ${isSelected() ? "selected" : ""}`}>
      {props.href ? (
        <Link
          to={props.href}
          onClick={props.closeMenu}
          className={`${props.className ?? ""}`}
        >
          {renderLink}
        </Link>
      ) : props.onClick ? (
        <a href="/" onClick={handleOnClick} className={props.className}>
          {renderLink}
        </a>
      ) : (
        <>{renderLink}</>
      )}
    </li>
  );
};

MenuLink.defaultProps = {
  liClassName: "",
  className: "",
};

export default MenuLink;
