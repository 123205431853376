import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";

import DrivingInfo from "./customer/DrivingInfo";
import EnquirySource from "./customer/EnquirySource";
import Legals from "./customer/Legals";
import PersonalDetails from "./customer/PersonalDetails";
import OccupationDetails from "./customer/OccupationDetails";

const CustomerInformation = (props) => {
  const [ tab, setTab ] = useState("personal");

  return (
    <div className="customer-information-form">
      <div className="customer-information-form-nav">
        <Nav variant="underline" activeKey={tab} onSelect={val => setTab(val)}>
          <Nav.Item>
            <Nav.Link eventKey="personal">Personal details</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="occupation">Occupation</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="driving">Driving information</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="legals">Legals</Nav.Link>
          </Nav.Item>
          { props.application?.applicationGuid && (
            <Nav.Item>
              <Nav.Link eventKey="source">Enquiry source</Nav.Link>
            </Nav.Item>
          )}
        </Nav>
      </div>

      <div className="customer-information-form-content">
        { tab === "personal" ? (
          <PersonalDetails
            application={props.application}
            schema={props.schema}
            onUpdate={props.onUpdate}
            changeTab={setTab}
          />
        ) : tab === "occupation" ? (
          <OccupationDetails
            application={props.application}
            schema={props.schema}
            onUpdate={props.onUpdate}
            changeTab={setTab}
          />
        ) : tab === "driving" ? (
          <DrivingInfo
            application={props.application}
            schema={props.schema}
            onUpdate={props.onUpdate}
            changeTab={setTab}
          />
        ) : tab === "legals" ? (
          <Legals
            application={props.application}
            schema={props.schema}
            onUpdate={props.onUpdate}
            setActiveTab={props.setActiveTab}
          />
        ) : tab === "source" && (
          <EnquirySource application={props.application} />
        )}
      </div>
    </div>
  )
}

export default CustomerInformation;
