import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { getPolicies, updatePolicy } from "services/admin";
import PolicyModal from "components/modals/PolicyModal";

import { ReactComponent as EditIcon } from "assets/images/edit.svg";
import { ReactComponent as InfoIcon } from "assets/images/info.svg";

const PoliciesPage = (props) => {

  const [ policies, setPolicies ] = useState([]);
  const [ showPolicyModal, setShowPolicyModal ] = useState(false);

  useEffect(() => {
    getPoliciesList()
  }, []);

  const getPoliciesList = () => {
    getPolicies()
      .then(res => {
        setPolicies(res.data);
      });
  }

  const handleManagePolicy = (data) => {
    let formData = new FormData();
    Object.keys(data).map(field => {
      formData.append(field, data[field]);
    });

    updatePolicy(showPolicyModal.id, formData)
      .then(res => {
        getPoliciesList();
      });

    setShowPolicyModal(false);
  }

  return (
    <>
      <div className="page-top">
        <h3 className="page-title">Policies</h3>
      </div>

      <div className="mt-5 w-100">
        <table className="table table-bordered text-center w-100 align-middle">
          <thead>
            <tr>
              <th>Policy Name</th>
              <th>Text</th>
              <th>PDF</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            { policies.map(policy => (
              <tr key={policy.id}>
                <td>{ policy.name }</td>
                <td>
                  { policy.terms ? (
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip><div dangerouslySetInnerHTML={{ __html: policy?.terms }} /></Tooltip>}
                      delay={{ show: 250, hide: 400 }}
                    >
                      <InfoIcon width={18} height={18} />
                    </OverlayTrigger>
                  ) : (
                    <i>Not set</i>
                  )}
                </td>
                <td>
                  { policy.url ? (
                    <a href={ policy.url } target="_blank">Click to view</a>
                  ) : (
                    <i>Not set</i>
                  )}
                </td>
                <td>
                  <button
                    onClick={e => setShowPolicyModal(policy)}
                    className="link"
                  >
                    <EditIcon width={18} height={18} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PolicyModal
        show={showPolicyModal !== false}
        policy={showPolicyModal}
        onCancel={() => setShowPolicyModal(false)}
        onConfirm={handleManagePolicy}
      />
    </>
  );
}

export default PoliciesPage;
