import React, { useEffect, useState } from "react";

import { createDealer, updateDealer, getDealersList, deleteDealer } from "services/admin";
import ConfirmModal from "components/modals/ConfirmModal";
import DealerModal from "components/modals/DealerModal";

import { ReactComponent as DeleteIcon } from "assets/images/delete.svg";
import { ReactComponent as EditIcon } from "assets/images/edit.svg";

const DealersListPage = (props) => {
  const [ dealers, setDealers ] = useState([]);
  const [ showConfirm, setShowConfirm ] = useState(null);
  const [ showDealerModal, setShowDealerModal ] = useState(false);

  useEffect(() => {
    getDealers();
  }, []);

  const getDealers = () => {
    getDealersList()
      .then(res => {
        setDealers(res.data);
      });
  }

  const handleDeleteDealer = () => {
    deleteDealer(showConfirm)
      .then(res => {
        let localDealers = [...dealers].filter(dealer => dealer.id !== showConfirm.id);
        setDealers(localDealers);

        setShowConfirm(null);
      });
  }

  const handleManageDealer = (data) => {
    let formData = new FormData();
    Object.keys(data).map(field => {
      formData.append(field, data[field]);
    });
    
    if (showDealerModal === null) {
      createDealer(formData)
        .then(res => {
          getDealers();
        });
    } else {
      updateDealer(showDealerModal.id, formData)
        .then(res => {
          getDealers();
        });
    }

    getDealers();
    setShowDealerModal(false);
  }

  return (
    <>
      <div className="page-top">
        <h3 className="page-title">Dealers</h3>
      </div>

      <div className="mt-5 w-100">
        <div className="d-flex justify-content-end mb-2">
          <button
            onClick={e => setShowDealerModal(null)}
            className="green"
          >
            Add Dealer
          </button>
        </div>

        <table className="table table-bordered text-center w-100">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Logo</th>
              <th>API Key</th>
              <th>Codeweaver ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            { dealers.map((dealer, index) => (
              <tr>
                <td className="align-middle">#{ dealer.id }</td>
                <td className="align-middle">{ dealer.name }</td>
                <td className="align-middle">
                  <img src={ dealer.logo } style={{ maxHeight: 40 }} />
                </td>
                <td className="align-middle">{ dealer.api_key }</td>
                <td className="align-middle">{ dealer.codeweaver_id }</td>
                <td className="align-middle">
                  <button
                    onClick={e => setShowDealerModal(dealer)}
                    className="link"
                  >
                    <EditIcon width={18} height={18} />
                  </button>

                  <button
                    className="link"
                    onClick={e => setShowConfirm(dealer)}
                  >
                    <DeleteIcon width={18} height={18} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <ConfirmModal
        show={showConfirm !== null}
        onCancel={() => setShowConfirm(null)}
        onConfirm={handleDeleteDealer}
        text="Are you sure you wish to delete this dealer?"
      />

      <DealerModal
        show={showDealerModal !== false}
        dealer={showDealerModal}
        onCancel={() => setShowDealerModal(false)}
        onConfirm={handleManageDealer}
      />
    </>
  );
}

export default DealersListPage;
