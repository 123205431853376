import CompliancePage from "pages/user/admin/CompliancePage";
import DealersListPage from "pages/user/admin/DealersListPage";
import PoliciesPage from "pages/user/admin/PoliciesPage";
import UsersListPage from "pages/user/admin/UsersListPage";

const routes = [
  {
    name: "users",
    path: "/",
    component: <UsersListPage />,
  },
  {
    name: "dealers",
    path: "/dealers",
    component: <DealersListPage />
  },
  {
    name: "policies",
    path: "/policies",
    component: <PoliciesPage />,
  },
  {
    name: "compliance",
    path: "/compliance",
    component: <CompliancePage />,
  },
];

export default routes;
