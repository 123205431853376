import axios from "./base";

export const searchApplications = (params) => {
  return axios.get('/dealer/applications/search', { params }).then(res => res.data);
}

export const getApplicationsList = (params) => {
  return axios.get('/dealer/applications', { params }).then(res => res.data);
}

export const getApplicationsByLender = (params) => {
  return axios.get('/dealer/applications/lender', { params }).then(res => res.data);
}

export const getApplication = (params) => {
  return axios.get('/dealer/applications/retrieve', { params }).then(res => res.data);
}

export const getApplicationSchema = () => {
  return axios.get('/dealer/applications/schema/update').then(res => res.data);
}

export const getClientApplications = (params) => {
  return axios.get('/dealer/clients/applications', { params }).then(res => res.data);
}

export const getOtherClientApplications = (params) => {
  return axios.get('/dealer/applications/related', { params }).then(res => res.data);
}

export const getClientPreQualifiedQuotes = (params) => {
  return axios.post('/dealer/clients/prequalified', params).then(res => res.data);
}

export const getCustomerHistory = (params) => {
  return axios.get('/dealer/clients/history', { params }).then(res => res.data);
}

export const updateCustomerStatus = (params) => {
  return axios.post('/dealer/clients/status', { ...params, _method: 'put' }).then(res => res.data);
}

export const getAdjustQuoteSchema = () => {
  return axios.get('/dealer/quote/schema').then(res => res.data);
}

export const adjustQuote = (params) => {
  return axios.post('/dealer/quote', params).then(res => res.data);
}

export const getPartExchangeSchema = () => {
  return axios.get('/dealer/px/schema').then(res => res.data);
}

export const getPartExchange = (params) => {
  return axios.post('/dealer/px', params).then(res => res.data);
}

export const getVrmSearchSchema = () => {
  return axios.get('/dealer/px/vrm/schema').then(res => res.data);
}

export const getVrmSearch = (params) => {
  return axios.post('/dealer/px/vrm', params).then(res => res.data);
}

export const updateApplication = (params) => {
  return axios.post('/dealer/applications', params).then(res => res.data);
}

export const updateApplicationVehicle = (params) => {
  return axios.post('/dealer/applications/vehicle', { ...params, _method: "put" }).then(res => res.data);
}

export const getCreateApplicationSchema = () => {
  return axios.get('/dealer/applications/schema/create').then(res => res.data);
}

export const createApplication = (params) => {
  return axios.post('/dealer/applications/create', params).then(res => res.data);
}

export const getLenderQuestions = (quoteId) => {
  return axios.get(`/dealer/applications/questions/${quoteId}`, { params: { quoteGuid: quoteId }}).then(res => res.data);
}

export const selectQuote = (params) => {
  return axios.post('/dealer/applications/select', params).then(res => res.data);
}

export const getBankingDetailsSchema = () => {
  return axios.get('/dealer/banking/schema').then(res => res.data);
}

export const updateBankingDetails = (params) => {
  return axios.post('/dealer/banking', params).then(res => res.data);
}

export const getSubmitQuoteSchema = () => {
  return axios.get('/dealer/applications/schema/submit').then(res => res.data);
}

export const submitQuote = (params) => {
  return axios.post('/dealer/applications/submit', params).then(res => res.data);
}

export const getApplicationDecision = (params) => {
  return axios.get('/dealer/applications/outcome', { params }).then(res => res.data);
}

export const getNotificationsCount = (params) => {
  return axios.get('/dealer/notifications', { params }).then(res => res.data);
}

export const getNotificationApplications = (params) => {
  return axios.get('/dealer/notifications/applications', { params }).then(res => res.data);
}

export const clearNotifications = () => {
  return axios.post('/dealer/notifications/clear', { _method: "put" }).then(res => res.data);
}

export const getMetaData = () => {
  return axios.get('/dealer/meta').then(res => res.data);
}

export const archiveApplication = (application) => {
  return axios.post(`/dealer/applications/archive?applicationGuid=${application}`, { _method: "put" }).then(res => res.data);
}

export const sendApplications = (params) => {
  return axios.post('/dealer/applications/send', params).then(res => res.data);
}

export const hasPxRequest = (application) => {
  return axios.get(`/dealer/applications/${application}/px`).then(res => res.data);
}

export const confirmPxRequest = (application) => {
  return axios.post(`/dealer/applications/${application}/px/confirm`).then(res => res.data);
}
