import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { clearNotifications } from "services";
import { setNotifications } from "store/actions/auth";

const Notifications = (props) => {
  const navigate = useNavigate();

  const notifications = useSelector(state => state?.auth?.notifications);

  const [ total, setTotal ] = useState(0);

  useEffect(() => {
    if (notifications) {
      setTotal(Object.values(notifications).reduce((prev, next) => prev + next));
    } else {
      setTotal(0);
    }
  }, [notifications]);

  const handleClearAll = () => {
    clearNotifications()
      .then(res => {
        props.onClear();
      });
  }

  const handleClick = (type) => {
    navigate(`customers?type=${type}`);
  }

  return (
    <div className={`notifications-menu ${props.className ?? ""}`}>
      <div className="notifications-menu-header">
        <div className="notifications-menu-title">Notifications ({ total })</div>
        <div className="notifications-menu-buttons">
          <div className="notifications-menu-clear" onClick={handleClearAll}>Clear all</div>
          <CloseButton variant="white" onClick={props.onHide} />
        </div>
      </div>
      <div className="notifications-menu-body">
        <p onClick={e => handleClick('NotSubmitted')}>You have { notifications?.totalNotSubmitted } customers that need submitting</p>
        <p onClick={e => handleClick('Approved')}>You have { notifications?.totalApproved } approved quotes</p>
        <p onClick={e => handleClick('Declined')}>You have { notifications?.totalDeclined } declined quotes</p>
        <p onClick={e => handleClick('PXRequests')}>You have { notifications?.totalPxRequests } part exchanges to be confirmed</p>
      </div>
    </div>
  );
}

Notifications.defaultProps = {
  onHide: () => {},
}

export default Notifications;
