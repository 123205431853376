import React from "react";
import { Col, Row } from "react-bootstrap";

const ResponsiveTable = (props) => {
  return (
    <div className={`responsive-table ${props.type}-table ${props.className}`}>
      <Row className="responsive-table-header d-none d-md-flex">
        { props.columns.map((header, index) => (
          <Col
            xs={header?.mobileWidth ?? 6}
            sm={header?.smallWidth}
            md
            className={`responsive-table-header-cell ${header?.headerClassName ?? ""}`}
            key={index}
          >
            { header.title }
          </Col>
        ))}
      </Row>

      { props.data.map((row, index) => (
        <Row className="responsive-table-row" key={index}>
          { props.columns.map(header => (
            <Col
              xs={header?.mobileWidth ?? 6}
              sm={header?.smallWidth}
              md
              className={`responsive-table-row-cell ${header?.className ?? ""} ${header.order ? `xs-order-${header.order} md-order-${header.order}` : ""}`}
              key={header.title}
            >
              { (typeof(header.showMobileHeader) === "undefined" || header.showMobileHeader) && (
                <div className="mobile-title d-block d-md-none">{ header.title }</div>
              )}

              { header.render && (
                <>{ header.render(row) }</>
              )}

              { header.index && (
                <>{ row[header.index] }</>
              )}
            </Col>
          ))}
        </Row>
      ))}

      { props.data.length === 0 && (
        <Row className="responsive-table-row no-data">
          <Col xs={12}>
            No data to dislay
          </Col>
        </Row>
      )}
    </div>
  );
}

ResponsiveTable.defaultProps = {
  type: "spaced",
  columns: [],
  data: [],
  className: "",
}

export default ResponsiveTable;
