import React, { useEffect, useState } from "react";
import { Accordion, Col, Form, Modal, Row } from "react-bootstrap";
import moment from "moment";
import * as yup from "yup";

import { getErrorLabels } from "helpers/errors";
import { getMonthOptions, getYearOptions } from "helpers/application";
import { getVehicleName } from "helpers/vehicle";
import { selectQuote, updateBankingDetails } from "services";
import Error from "components/Error";
import LenderLogo from "components/LenderLogo";

const EmploymentStep = (props) => {
  const defaultEmployerObject = {
    employerName: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    addressLine4: "",
    postCode: "",
    yearsAtJob: "0",
    monthsAtJob: "0",
  };

  const [ bankDetails, setBankDetails ] = useState({
    nameOnAccount: "",
    accountNumber: "",
    sortCode: "",
  });

  const [ currentEmployer, setCurrentEmployer ] = useState(defaultEmployerObject);
  const [ errors, setErrors ] = useState({});
  const [ previousEmployer, setPreviousEmployer ] = useState([ defaultEmployerObject ]);
  const [ selectedQuote, setSelectedQuote ] = useState(null);

  const validationSchema = yup.object({
    bankAccountNumber: yup.string().length(8).required(),
    employerAddress: yup.object().shape({
      streetNumber: yup.string().required(),
      streetName: yup.string().required(),
      postcode: yup.string().required(),
    }),
    employerName: yup.string().required(),
    nameOnAccount: yup.string().required(),
    sortCode: yup.string().length(6).matches(/[0-9]{6}/).required(),
    totalEmploymentMonths: yup.number().min(36).required(),
  });

  useEffect(() => {
    if (props?.quote) {
      selectQuote({ quoteGuid: props?.quote?.quoteGuid })
        .then(res => {
          setSelectedQuote(res);
        });
    }

    if (props?.application) {
      let years = (props?.application?.client?.monthsAtJob ? Math.floor(props?.application?.client?.monthsAtJob / 12) : 0);
      let months = (props?.application?.client?.monthsAtJob ? props?.application?.client?.monthsAtJob % 12 : 0);

      setCurrentEmployer({
        employerName: props.application?.client?.employerName,
        addressLine1: props?.application?.client?.employerAddress?.addressLine1,
        addressLine2: props?.application?.client?.employerAddress?.addressLine2,
        addressLine3: props?.application?.client?.employerAddress?.addressLine3,
        addressLine4: props?.application?.client?.employerAddress?.addressLine4,
        postCode: props?.application?.client?.employerAddress?.postCode,
        yearsAtJob: years,
        monthsAtJob: months,
      });

      if (props.application?.client?.employmentHistory?.length > 0) {
        let previousEmployment = props.application?.client?.employmentHistory.map(history => {
          return {
            employerName: history?.employerName,
            addressLine1: history?.employerAddress?.streetNumber,
            addressLine2: history?.employerAddress?.streetName,
            addressLine3: "",
            addressLine4: "",
            postCode: history?.employerAddress?.postcode,
            yearsAtJob: history.yearsAtJob,
            monthsAtJob: history?.monthsAtJob,
          }
        });

        setPreviousEmployer(previousEmployment);
      }

      setBankDetails({
        nameOnAccount: props?.application?.client?.nameOnAccount,
        accountNumber: props?.application?.client?.accountNumber,
        sortCode: props?.application?.client?.sortCode?.replace(/-/g, ''),
      });
    }
  }, []);

  const getPrimaryEmploymentMonths = () => {
    return (parseInt(currentEmployer.yearsAtJob) * 12) + parseInt(currentEmployer.monthsAtJob);
  }

  const getTotalEmploymentMonths = () => {
    let total = (parseInt(currentEmployer.yearsAtJob) * 12) + parseInt(currentEmployer.monthsAtJob);

    previousEmployer.map(employer => {
      total += (parseInt(employer.yearsAtJob) * 12) + parseInt(employer.monthsAtJob);
    });

    return total;
  }

  const handleCurrentEmployerChange = (e) => {
    setCurrentEmployer({
      ...currentEmployer,
      [e.target.name]: e.target.value,
    });
  }

  const handlePreviousEmployerChange = (index, e) => {
    let localPreviousEmployer = [ ...previousEmployer ];
    localPreviousEmployer = localPreviousEmployer.map((emp, i) => {
      if (i === index) {
        emp[e.target.name] = e.target.value;
      }
      return emp;
    });

    if (["monthsAtJob", "yearsAtJob"].includes(e.target.name)) {
      if (getTotalEmploymentMonths() < 36) {
        if (previousEmployer.length > 0) {
          let lastEmployer = previousEmployer[previousEmployer.length - 1];
          let isLastBlank = JSON.stringify(lastEmployer) === JSON.stringify(defaultEmployerObject);

          if (!isLastBlank) {
            let localPreviousEmployer = [...previousEmployer];
            localPreviousEmployer.push(defaultEmployerObject); 
            setPreviousEmployer(localPreviousEmployer);
            return;
          }
        }
      }
    }

    setPreviousEmployer(localPreviousEmployer);
  }

  const handleBankDetailsChange = (e) => {
    setBankDetails({
      ...bankDetails,
      [e.target.name]: e.target.value.replace(/-/g, ""),
    });
  }

  const handleProceed = () => {
    let employmentHistoryObj = {};

    if (getPrimaryEmploymentMonths() < 36) {
      employmentHistoryObj.employmentHistory = [];

      previousEmployer.map(prevEmp => {
        if (JSON.stringify(prevEmp) !== JSON.stringify(defaultEmployerObject)) {
          employmentHistoryObj.employmentHistory.push({
            employerAddress: {
              streetNumber: prevEmp?.addressLine1,
              streetName: prevEmp?.addressLine2,
              postcode: prevEmp.postCode,
            },
            employerName: prevEmp?.employerName,
            monthsAtJob: parseInt(prevEmp?.monthsAtJob),
            yearsAtJob: parseInt(prevEmp?.yearsAtJob),
          })
        }
      });
    }

    let updateObject = {
      affordCheck: moment(selectedQuote?.affordCheck ?? undefined).format("YYYY-MM-DD HH:mm:ss"),
      bankAccountNumber: bankDetails?.accountNumber,
      employerAddress: {
        streetNumber: currentEmployer?.addressLine1,
        streetName: currentEmployer?.addressLine2,
        postcode: currentEmployer.postCode,
      },
      employerName: currentEmployer?.employerName,
      ...employmentHistoryObj,
      nameOnAccount: bankDetails?.nameOnAccount,
      quoteDetailGuid: props.quote.quoteGuid,
      sortCode: bankDetails?.sortCode,      
      watchExplainerVideoDate: moment(selectedQuote?.watchExplainerVideoDate ?? undefined).format("YYYY-MM-DD HH:mm:ss"),
    };

    validationSchema.validate({
      ...updateObject,
      totalEmploymentMonths: getTotalEmploymentMonths(),
    }, 
    {
      abortEarly: false
    })
      .then(() => {
        updateObject.sortCode = formatSortCode();
        props.onProceed(updateObject)
      })
      .catch(err => {
        setErrors(getErrorLabels(err));
      });
  }

  const formatSortCode = () => {
    if (bankDetails?.sortCode) {
      if (bankDetails?.sortCode.match(/^[0-9]{5,6}$/)) {
        return bankDetails?.sortCode?.replace(/^([0-9]{2})([0-9]{2})([0-9]{1,2})$/, "$1-$2-$3");
      } else if (bankDetails?.sortCode.match(/^[0-9]{3,4}$/)) {
        return bankDetails?.sortCode?.replace(/^([0-9]{2})([0-9]{1,2})$/, "$1-$2");
      } else {
        return bankDetails?.sortCode;
      }
    }
    return "";
  }

  return (
    <>
      <Modal.Header closeButton>
        Submit application to lender
      </Modal.Header>

      <Modal.Body className="submit-to-lender">
        <p className="info">We need more information from the customer to submit this quote to the lender.</p>

        <Row>
          <Col xs={12} md={6}>
            <h3 className="section-title">Vehicle details</h3>

            <Row>
              <Col xs={6}>
                { getVehicleName(props.application?.vehicle) }
              </Col>
              <Col xs={6}>
                £{ props.application?.vehicle?.salesValue.toLocaleString("en-GB") }
              </Col>
              <Col xs={12}>
                <div className="vehicle-reg">
                  { props.application?.vehicle?.registrationNumber }
                </div>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6}>
            <h3 className="section-title">Finance details</h3>

            <Row>
              <Col xs={6}>
                <LenderLogo image={props.lender?.lenderLogo} />
              </Col>
              <Col xs={6}>
                £{ props.quote?.payments.toLocaleString("en-GB") } p/m
              </Col>
              <Col xs={12}>
                <button
                  onClick={props.onBack}
                  className="link"
                >
                  View full pre-approved quote
                </button>
              </Col>
            </Row>
          </Col>
        </Row>

        <h3 className="section-title">Employment details</h3>

        <Accordion>
          <Accordion.Item eventKey="current">
            <Accordion.Header>Current employer details</Accordion.Header>
            <Accordion.Body>
              <Row className="mb-3">
                <Col xs={12} md={6}>
                  <label>Name of employer</label>
                  <input name="employerName" value={currentEmployer.employerName} onChange={handleCurrentEmployerChange} />
                  <Error name="employerName" errors={errors} />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12} md={6}>
                  <label>Employer address</label>
                  <input
                    name="addressLine1"
                    value={currentEmployer.addressLine1}
                    onChange={handleCurrentEmployerChange}
                    placeholder="Street number"
                  />
                  <Error name="employerAddress.streetNumber" errors={errors} />
                  <input
                    name="addressLine2"
                    value={currentEmployer.addressLine2}
                    onChange={handleCurrentEmployerChange}
                    placeholder="Street name"
                  />
                  <Error name="employerAddress.streetName" errors={errors} />
                </Col>
                <Col xs={12} md={6}>
                  <label>Employer postcode</label>
                  <input name="postCode" value={currentEmployer.postCode} onChange={handleCurrentEmployerChange} />
                  <Error name="employerAddress.postcode" errors={errors} />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12} md={6}>
                  <label>Time at current employer</label>
                  <Row>
                    <Col xs={6}>
                      { currentEmployer.yearsAtJob > 0 && (
                        <>{ currentEmployer.yearsAtJob } years</>
                      )}

                      { currentEmployer.monthsAtJob > 0 && (
                        <>{ currentEmployer.monthsAtJob } months</>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        { getPrimaryEmploymentMonths() < 36 && (
          <>
            <p className="additional-text">As the customer has been with their current employer for less than 3 years, we need details of past employment.</p>

            <Accordion>
              { previousEmployer.map((employer, index) => (
                <Accordion.Item eventKey={`previous-${index}`}> 
                  <Accordion.Header>Previous employer details</Accordion.Header>
                  <Accordion.Body>
                    <Row className="mb-3">
                      <Col xs={12} md={6}>
                        <label>Name of employer</label>
                        <input name="employerName" value={employer.employerName} onChange={e => handlePreviousEmployerChange(index, e)} />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} md={6}>
                        <label>Employer address</label>
                        <input
                          name="addressLine1"
                          value={employer.addressLine1}
                          onChange={e => handlePreviousEmployerChange(index, e)}
                          placeholder="Street number"
                        />
                        <input
                          name="addressLine2"
                          value={employer.addressLine2}
                          onChange={e => handlePreviousEmployerChange(index, e)}
                          placeholder="Street name"
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <label>Employer postcode</label>
                        <input name="postCode" value={employer.postCode} onChange={e => handlePreviousEmployerChange(index, e)} />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} md={6}>
                        <label>Time at employer</label>
                        <Row>
                          <Col xs={6}>
                            <Form.Select name="yearsAtJob" value={employer.yearsAtJob} onChange={e => handlePreviousEmployerChange(index, e)}>
                              { getYearOptions().map(option => (
                                <option value={option.value} key={option.value}>{ option.label }</option>
                              ))}
                            </Form.Select>
                          </Col>
                          <Col xs={6}>
                            <Form.Select name="monthsAtJob" value={employer.monthsAtJob} onChange={e => handlePreviousEmployerChange(index, e)}>
                              { getMonthOptions().map(option => (
                                <option value={option.value} key={option.value}>{ option.label }</option>
                              ))}
                            </Form.Select>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </>
        )}

        <h3 className="section-title">Bank details</h3>

        <Row>
          <Col xs={12} md={4}>
            <label>Name on account</label>
            <input name="nameOnAccount" value={bankDetails?.nameOnAccount} onChange={handleBankDetailsChange} />
            <Error name="nameOnAccount" errors={errors} />
          </Col>
          <Col xs={12} md={4}>
            <label>Account number</label>
            <input
              name="accountNumber"
              value={bankDetails?.accountNumber}
              onChange={handleBankDetailsChange}
              maxLength={8}
            />
            <Error name="bankAccountNumber" errors={errors} />
          </Col>
          <Col xs={12} md={4}>
            <label>Sort code</label>
            <input
              name="sortCode"
              value={formatSortCode()}
              onChange={handleBankDetailsChange}
            />
            <Error name="sortCode" errors={errors} />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        <button
          onClick={props.onBack}
          className="outline"
        >
          Go back
        </button>
        <button
          onClick={handleProceed}
          className="dark"
        >
          Next - customer's affordability
        </button>
      </Modal.Footer>
    </>
  );
}

export default EmploymentStep;
